import { configureStore } from "@reduxjs/toolkit";
import accountCreationApiReducer from "../reducer/accountCreationApiReducer";
import websitePlan from "../reducer/websitePlan";
import adminPanelSetting from "../reducer/adminSettings";
import switchSites from "../reducer/switchSites";
import superAdminReducer from "../reducer/SuperAdminReducer";
import carePlanReducer from "../reducer/carePlanReducer";
import JournalReducer from "../reducer/JournalReducer";
import msgReducer from "../reducer/msgReducer";
import caresiteDataReducer from "../reducer/caresiteDataReducer";
import portalReducer from "../reducer/portalReducer";
import eventLogsSlice from "../reducer/eventLogsReducer";
import adminClientSlice from "../reducer/admin_clients_reducer";

export const store = configureStore({
  reducer: {
    accountCreationApiReducer: accountCreationApiReducer,
    websitePlans: websitePlan,
    adminPanelSetting: adminPanelSetting,
    switchSites: switchSites,
    superAdminReducer: superAdminReducer,
    carePlanReducer: carePlanReducer,
    JournalReducer: JournalReducer,
    msgReducer: msgReducer,
    portalReducer:portalReducer,
    caresiteDataReducer: caresiteDataReducer,
    eventLogsReducer: eventLogsSlice,
    adminClientReducer: adminClientSlice,
  },
});
