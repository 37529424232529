import React, { useState, useEffect } from "react";
import "../Clients/clients.css";
import { useHistory } from "react-router-dom";
import NoRowsOverlay from "../../components/NoRowsOverlays";
// import datechangeHandler from "../../utils/datechangehandler";
import { IoIosArrowForward } from "react-icons/io";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import CareMagnusTable from "../../utils/CareMagnusTable";
import { formatDateToDDMMYYYY } from "../../utils/CommonFunctions";

const chatUserImg = "/images/chatUser.svg";
const clientGroupImg = "/images/clientGroup.svg";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const columns = [
  // { field: "id", headerName: "No.", width: 30 },
  {
    field: "image",
    headerName: "",
    width: 55,
    renderCell: (params) => <ProfileImgName params={params} />,
  },
  {
    field: "name",
    headerName: "Name",
    width: 180,
  },
  {
    field: "roomNo",
    headerName: "Room No",
    sortable: false,
    disableColumnMenu: true,
    width: 80,
  },
  { field: "location", headerName: "Location", flex: 1, minWidth: 190 },
  {
    field: "contractStartdate",
    headerName: "Contract Start Date",
    flex: 1,
    minWidth: 150,
    align: "center",
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "advance_Directive",
    headerName: "Advance Directive",
    flex: 1,
    minWidth: 140,
  },
  { field: "hrs_of_service", headerName: "Hrs Of Service", width: 120 },
  {
    field: "overdueTasks",
    headerName: "Overdue Tasks",
    width: 120,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "toDos",
    headerName: "To Dos",
    width: 70,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => <StatusButton params={params} />,
  },
  {
    id: "arrow",
    width: 50,
    numeric: true,
    disablePadding: true,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => <RedirectionIcon params={params} />,
  },
];

export default function ClientNewTable(props) {
  let { rows, searchText, selectedRows, handleRowSelection } = props;

  const { currentPlanName, currentCareSiteData } = useSelector(
    (state) => state.caresiteDataReducer
  );
  const isPlanBase = currentPlanName === "Base";

  const filteredRows = searchText
    ? rows.filter(
        (row) =>
          row.name?.toLowerCase().includes(searchText) ||
          row.location?.toLowerCase().includes(searchText)
      )
    : rows;

  let filteredCols = isPlanBase
    ? columns?.filter((item) => item.field !== "hrs_of_service")
    : columns;
  let filterRoomNo =
    currentCareSiteData?.carefacility == "agency"
      ? columns?.filter((item) => item.field !== "roomNo")
      : filteredCols;

  return (
    <>
      <CareMagnusTable
        tableHeight="75vh"
        idParam="id"
        columns={filterRoomNo}
        rows={filteredRows}
        selectedRow={selectedRows}
        handleRowSelection={handleRowSelection}
      />
      {/* <CustomDataGrid
        columns={filterRoomNo}
        rows={filteredRows}
        // onRowClick={handleRowClick}
        // autoHeight
        checkboxSelection
        onSelectionModelChange={handleRowSelection}
        selectionModel={selectedRows}
        disableSelectionOnClick
        getRowId={(row) => row?.id}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: NoRowsOverlay,
        }}
        pageSize={8}
      /> */}
    </>
  );
}

const ProfileImgName = (props) => {
  let { params } = props;
  const dp =
    params.row.clientType === "group" ? clientGroupImg : params.row.image;
  return (
    <span className="profle_img_box">
      <img alt="" className="profile_img_table" src={dp ? dp : chatUserImg} />{" "}
      {/* <strong
        style={{ fontWeight: "400" }}
      >{`${params.row.first_Name} ${params.row.last_Name}`}</strong> */}
    </span>
  );
};

const RedirectionIcon = (props) => {
  const history = useHistory();
  let { params } = props;
  return (
    <>
      {params.row.clientType === "group" ? (
        ""
      ) : (
        <IoIosArrowForward
          style={{ cursor: "pointer", fontSize: "20px" }}
          onClick={() => {
            history.push(`/admin/clients/details/profile`, {
              clientId: params?.row?.id,
            });
          }}
        />
      )}
    </>
  );
};

const StatusButton = (props) => {
  let { params } = props;
  return (
    <>
      {params?.row?.status === "active" && (
        <button className={`btn btn-sm btn-success table_btn_font`}>
          Active
        </button>
      )}
      {params?.row?.status === "closed" && (
        <button className={`btn btn-sm btn-danger table_btn_font`}>
          Inactive
        </button>
      )}
      {params?.row?.status === "moving_out" && (
        <button
          style={{ width: "80px" }}
          className={`btn btn-sm btn_table btn-warning table_btn_font`}
        >
          Moving Out
          <br /> {params?.row?.dateOfMovingOut.split("T")[0]}
        </button>
      )}
    </>
  );
};
