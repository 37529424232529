import React, { useState, useEffect } from "react";
import axios from "axios";
import { ComplianceDocsTypes } from "../../constants/constants";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import NoRowsOverlay from "../../components/NoRowsOverlays";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { httpAdmin } from "../../Apis/commonApis";
import PreviewButton from "../../components/PreviewButton";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import {
  disablePastDate,
  formatDateToDDMMYYYY,
  getFileOriginalName,
} from "../../utils/CommonFunctions";
import { useSelector } from "react-redux";
import { ShowSwal } from "../../utils/alertSwal";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const columns = [
  { field: "docName", headerName: "Doc Name", width: 190 },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    editable: false,
  },
  {
    field: "creationDate",
    headerName: "Creation date",
    width: 170,
    editable: false,
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 150,
    editable: false,
  },
  {
    field: "review",
    headerName: "Next Review",
    width: 150,
    editable: false,
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "image",
    headerName: "File",
    width: 50,
    editable: false,
    renderCell: (params) => <PreviewButton filedata={params} />,
  },
  {
    field: "fileName",
    headerName: "",
    width: 300,
    editable: false,
  },
];

const AddEditComplianceDocs = (props) => {
  const { clientAllData, clientId, ComplianceDocsaccess } = props;

  const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
  const isPlanBase = currentPlanName === "Base";

  const [isOpen, setIsOpen] = useState("");
  const [editComplianceDocs, setEditComplianceDocs] = useState([]);
  const [editDocData, setEditDocData] = useState(null);
  const [ids, setIDs] = useState([]);

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isCarePlan, setIsCarePlan] = useState(false);

  const {
    register,
    handleSubmit,
    unregister,
    getValues,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    setIDs(rowSelectionModel);
  }, [rowSelectionModel]);

  useEffect(() => {
    if (clientAllData) {
      setDocDataFunc(clientAllData?.complianceDocs);
    }
  }, [clientAllData]);

  function setEditFormData(id) {
    const docdata = editComplianceDocs.find((mid) => mid.id == id);
    setEditDocData(docdata);
    setValue("docName", docdata.docName);
    setValue("docType", docdata.type);
    setValue("review", docdata.review);
    setIsOpen("Edit");
  }

  function handleAddEditDoc(postData) {
    if (isOpen === "Add") {
      createNewDoc(postData);
    } else {
      editDocs(postData);
    }
  }

  const createNewDoc = async (postData) => {
    let formdata = new FormData();
    let user = JSON.parse(localStorage.getItem("userData"));
    let fullName = `${user.firstName} ${user.lastName} (${user.userType})`;

    formdata.append("docName", postData.docName);
    formdata.append("type", postData.docType);
    formdata.append("creationDate", new Date().toJSON());
    formdata.append("createdBy", fullName);
    formdata.append("review", postData.review);
    formdata.append("image", postData.uploadFile[0]);

    if (isPlanBase) {
      let fileType = postData?.uploadFile[0]?.type;
      if (
        fileType &&
        (fileType?.includes("audio") || fileType?.includes("video"))
      ) {
        ShowSwal(false, "Audio and Video files are not allowed in Base Plan");
        return;
      }
    }

    handleCloseModal();
    const res = await httpAdmin(
      `complianceDocs/${clientId}`,
      "post",
      formdata,
      "formdata"
    );
    if (res.status) {
      reset();
      getClientbyId();
    }
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  };

  async function editDocs(postData) {
    const editData = {
      complianceDocs_id: editDocData.id,
      docName: postData.docName,
      type: postData.docType,
      review: postData.review,
    };
    handleCloseModal();
    let res = await httpAdmin(
      `editcomplianceDocs/${clientId}`,
      "post",
      editData,
      null
    );
    if (res.status) {
      reset();
      getClientbyId();
    }
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  }

  const getClientbyId = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/clientbyId?clientId=${clientId}&careHomeId=${currentCareSiteId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let ClientDocs = res.data.clientData?.complianceDocs;
        setDocDataFunc(ClientDocs);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  function setDocDataFunc(ClientDocs) {
    let custumArray = [];
    if (ClientDocs) {
      ClientDocs.forEach((elem) => {
        custumArray.push({
          id: elem._id,
          createdBy: elem.createdBy,
          creationDate: elem.creationDate ?? "",
          docName: elem.docName,
          image: elem.image,
          review: elem.review ?? "",
          type: elem.type,
          fileName: elem?.image ? getFileOriginalName(elem?.image) : "",
        });
      });

      setEditComplianceDocs(custumArray);
    }
  }
  // ---------------------     delete multiple  api    ----------------------------------  //

  async function deletemultiple() {
    let body = {
      clientId: clientId,
      complianceDocId: ids,
    };

    const res = await httpAdmin("deleteComplianceDocs", "post", body, null);
    if (res.status) {
      getClientbyId();
      handleCloseDeletePopup();
    }
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  }

  function handleCloseModal() {
    setIsOpen("");
    reset();
    setEditDocData(null);
  }

  return (
    <>
      {currentPlanName === "Turbo" || currentPlanName === "Advanced" ? (
        <div className="row">
          <div className="col-md-12">
            <div className="btns_head topButtonShow">
              <h5 className="tb_title mt-0">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Actions"
                  className="d-flex justify-content-end"
                  // style={{ marginBlock: "-30px" }}
                >
                  <Dropdown.Item
                    onClick={() => {
                      setIsOpen("Add");
                    }}
                    disabled={ComplianceDocsaccess !== "full"}
                  >
                    Add Docs
                  </Dropdown.Item>
                  <Dropdown.Item
                    // className="action_table_btn"
                    disabled={ComplianceDocsaccess !== "full"}
                    onClick={() => {
                      if (ids.length === 1) {
                        setEditFormData(ids[0]);
                      } else {
                        swal(
                          "Failed",
                          "Please Select only one compliance doc",
                          "error"
                        );
                      }
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={ComplianceDocsaccess !== "full"}
                    onClick={() => {
                      if (ids.length >= 1) handleShowDeletePopup(true);
                      else
                        swal(
                          "Failed",
                          "Please Select at least one compliance doc",
                          "error"
                        );
                    }}
                  >
                    Delete{" "}
                  </Dropdown.Item>
                </DropdownButton>
              </h5>
            </div>

            <div style={{ height: "80vh", width: "100%" }}>
              <CustomDataGrid
                columns={columns}
                rows={editComplianceDocs}
                // autoHeight
                checkboxSelection
                onSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                disableSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowsOverlay,
                }}
              />
            </div>

            {/* add compliance docs */}
            <Modal show={isOpen !== ""} onHide={handleCloseModal}>
              <Modal.Body>
                <div
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <form onSubmit={handleSubmit(handleAddEditDoc)}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          {isOpen} Docs
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={handleCloseModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Doc Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                editDocData ? editDocData?.docName : ""
                              }
                              {...register("docName", {
                                required: true,
                              })}
                              name="docName"
                            />
                            {errors?.docName?.type === "required" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Type</label>
                            {/* React Select Along with React Hook Form */}
                            <select
                              className="form-select"
                              defaultValue={
                                editDocData ? editDocData?.docType : ""
                              }
                              {...register("docType", {
                                required: true,
                              })}
                              name="docType"
                            >
                              {ComplianceDocsTypes.map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            {errors?.docType?.type === "required" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <label className="form-label">Creation Date</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("creationDate", {
                                required: true,
                              })}
                              name="creationDate"
                            />

                            {errors?.creationDate?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                          </div> */}
                          {/* <div className="col-md-12 mb-3">
                            <label className="form-label">Created By</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("createdBy", {
                                required: true,
                              })}
                              name="createdBy"
                            />
                            {errors?.createdBy?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                          </div> */}
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Next Review</label>
                            <input
                              type="date"
                              className="form-control"
                              defaultValue={
                                editDocData ? editDocData?.review : ""
                              }
                              {...register("review", {
                                required: false,
                              })}
                              min={disablePastDate()}
                              name="review"
                            />{" "}
                            {errors?.review?.type === "required" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>
                          {isOpen === "Add" && (
                            <div className="col-md-12 mb-3">
                              <label className="form-label">File</label>
                              <input
                                type="file"
                                className="form-control"
                                {...register("uploadFile", {
                                  required: "file is required",
                                })}
                                accept={isCarePlan ? ".pdf" : "all"}
                                onChange={(e) => {
                                  unregister("uploadFile");
                                }}
                                name="uploadFile"
                              />
                              {errors?.uploadFile?.type === "required" && (
                                <p className="error_style">
                                  This field is required
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="submit" className="btn btn-success">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </Modal>

            {/* delete Compliance Docs */}
            <Modal
              className="viewModal"
              show={isDeleteOpen}
              onHide={handleCloseDeletePopup}
            >
              <Modal.Header>
                <Modal.Title>
                  <span>Delete Compliance Docs</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <p>
                        Are you sure you want to delete this Compliance{" "}
                        {ids.length > 1 ? "docs" : "doc"} ?
                      </p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <button
                    className="btn submitBtn"
                    onClick={handleCloseDeletePopup}
                  >
                    No
                  </button>
                  <button
                    className="btn submitBtn"
                    onClick={() => {
                      deletemultiple();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      ) : (
        <div>This feature is not available in the {currentPlanName} Plan</div>
      )}
    </>
  );
};

export default AddEditComplianceDocs;
