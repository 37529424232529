import React, { useEffect, useMemo, useState } from "react";
import {
  NavLink,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import "../Invoicing/invoicing.css";
// import {BsFilterSquare} from "react-icons/bs";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import DataTable from "./configInvoiceUser";
import cx from "./sidecards.module.css";
import Select from "react-select";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
import { httpAdmin } from "../../Apis/commonApis";
import { useSelector } from "react-redux";

export default function InvoiceConfig(props) {
  const { searchText } = props;

  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(
      (item) => item.moduleName === "Invoicing"
    );
    return findCurrentModule;
  }, [accessRoles]);

  const [show, setShow] = useState(false);
  const [canEditInvProfile, setCanEditInvProfile] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ------------ Basic Care Home Invoice Details (Left Side) of Config (Show and Hide Handler) ------------- //
  const [showcarehomeDetailsModal, setshowcarehomeDetailsModal] =
    useState(false);
  const handleClosecarehomeDetailsModal = () =>
    setshowcarehomeDetailsModal(false);
  const handleshowcarehomeDetailsModal = () => {
    if (moduleAccess?.access === "full") {
      setshowcarehomeDetailsModal(true);
    }
  };

  // ----------------------------  Edit Basic Care Home Invoice Details Fields  ------------------------------ //

  const [invNumFormat, setinvNumFormat] = useState("");
  const [careHomeInvoiceDetail, setCareHomeInvoiceDetail] = useState(null);

  const [taxRate, setTaxRate] = useState("");
  const [loading, setLoading] = useState(false);

  // -----------  Care Home Invoice Emails Detail (Left Side) of Config (Show and Hide Handler) ------------ //

  const [showcarehomeEmailsDetailsModal, setshowcarehomeEmailsDetailsModal] =
    useState(false);
  const handleClosecarehomeEmailsDetailsModal = () =>
    setshowcarehomeEmailsDetailsModal(false);
  const handleshowcarehomeEmailsDetailsModal = () => {
    if (moduleAccess?.access === "full") {
      setshowcarehomeEmailsDetailsModal(true);
    }
  };

  // ----------------------------  Edit Care Home Invoice Emails Details Fields  ---------------------------- //
  const [invoiceEmailCc, setInvoiceEmailCc] = useState("");
  const [invoiceEmailBcc, setInvoiceEmailBcc] = useState("");
  const [paymentReceiptEmailCc, setPaymentReceiptEmailCc] = useState("");
  const [paymentReceiptEmailBcc, setPaymentReceiptEmailBcc] = useState("");

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ------------------------- Pay As You Go Tiggering Options and functions  ---------------------- //
  let [selectedpayasyougooptions, setSelectedPayasyougooptions] = useState("");

  let payasyougooptions = [
    { value: "UponShiftEnd", label: "Upon shift ending" },
    // { value: "UponShiftCreation", label: "Upon shift Creation" },
    { value: "UponShiftAssign", label: "Upon shift assignment" },
  ];

  function payAsYouGoHandleChange(event) {
    setSelectedPayasyougooptions(event);
  }

  // -------------------------  Payment Deadline  Options and functions  ---------------------- //
  let [selectedpaymentDeadline, setSelectedPaymentDeadline] = useState("");

  let paymentDeadlineOptions = [
    { value: "7", label: "1 Week" },
    { value: "14", label: "2 Weeks" },
    { value: "21", label: "3 Weeks" },
    { value: "28", label: "4 Weeks" },
  ];

  function paymentDeadlineHandleChange(event) {
    setSelectedPaymentDeadline(event);
  }

  // ---------------------------------- Edit Care Home Invoice Details  ------------------------------- //

  const editCareHomeInvoiceDetails = async (type) => {
    setLoading("basic");
    let data = {};
    if (type === "emails") {
      data = {
        careHomeId: currentCareSiteId,
        emails: {
          invoiceCC: [
            {
              email: invoiceEmailCc,
            },
          ],
          invoiceBCC: [
            {
              email: invoiceEmailBcc,
            },
          ],
          PaymentReceiptCC: [
            {
              email: paymentReceiptEmailCc,
            },
          ],
          PaymentReceiptBCC: [
            {
              email: paymentReceiptEmailBcc,
            },
          ],
        },
      };
    }
    if (type === "basic") {
      let payAsOpt = selectedpayasyougooptions?.value
        ? selectedpayasyougooptions?.value
        : selectedpayasyougooptions;
      data = {
        careHomeId: currentCareSiteId,
        paymentDeadline: selectedpaymentDeadline?.value,
        taxRate: taxRate,
        invNumbFormat: invNumFormat,
        trigger: payAsOpt,
      };
    }
    let res = await httpAdmin("editCarehome", "post", data, null);
    if (res.status) {
      getCareHomeInvoiceDetail();
    }
    setLoading("");
    handleClosecarehomeDetailsModal();
    handleClosecarehomeEmailsDetailsModal();
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  };

  useEffect(() => {
    getCareHomeInvoiceDetail();
  }, []);

  const getCareHomeInvoiceDetail = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getCarehomeInvById?careHomeId=${currentCareSiteId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let careData = res.data.careHomeData[0];
        let canEditInvProf =
          careData?.paymentDeadline &&
          careData?.invNumbFormat &&
          careData?.taxRate >= 0 &&
          careData?.trigger;
        setCanEditInvProfile(canEditInvProf);
        setCareHomeInvoiceDetail(careData);
        // let payDeadLine = careData?.paymentDeadline ? careData?.paymentDeadline : 7;
        if (careData?.paymentDeadline) {
          setSelectedPaymentDeadline({
            value: careData?.paymentDeadline,
            label: `${careData?.paymentDeadline / 7} Week`,
          });
        }
        setSelectedPayasyougooptions(
          payasyougooptions.find((item) => item.value === careData?.trigger)
        );
        setinvNumFormat(careData?.invNumbFormat);
        setTaxRate(careData?.taxRate);
        let mails = res.data.careHomeData[0]?.emails;
        setInvoiceEmailCc(
          mails.invoiceCC[0]?.email ? mails.invoiceCC[0]?.email : ""
        );
        setInvoiceEmailBcc(
          mails.invoiceBCC[0]?.email ? mails.invoiceBCC[0]?.email : ""
        );
        setPaymentReceiptEmailCc(
          mails.PaymentReceiptCC[0]?.email
            ? mails.PaymentReceiptCC[0]?.email
            : ""
        );
        setPaymentReceiptEmailBcc(
          mails.PaymentReceiptBCC[0]?.email
            ? mails.PaymentReceiptBCC[0]?.email
            : ""
        );
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  return (
    <>
      <div className="">
        <div className="row" style={{ justifyContent: "space-between" }}>
          <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
            <h5>
              Basic Details
              <NavLink
                to="#"
                className={`${cx.editIcon}`}
                onClick={handleshowcarehomeDetailsModal}
              >
                Edit
              </NavLink>
            </h5>
            <ul style={{ fontSize: "15px" }}>
              <li>
                Payment Deadline:{" "}
                {careHomeInvoiceDetail?.paymentDeadline
                  ? `${
                      +careHomeInvoiceDetail?.paymentDeadline / 7
                    } week from the sent date`
                  : ""}
              </li>
              <li>
                Invoice Prefix Numbering Format:{" "}
                {careHomeInvoiceDetail?.invNumbFormat &&
                  careHomeInvoiceDetail?.invNumbFormat}
              </li>
              <li>
                Tax Rate:{" "}
                {careHomeInvoiceDetail?.taxRate &&
                  +careHomeInvoiceDetail?.taxRate}{" "}
                %
              </li>
              <li>
                Pay-As-You-Go Invoice Generation Trigger:{" "}
                {careHomeInvoiceDetail?.trigger &&
                  careHomeInvoiceDetail?.trigger}
              </li>
              <li></li>
            </ul>
          </div>
          <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
            <h5>
              Emails
              <NavLink
                to="#"
                className={`${cx.editIcon}`}
                onClick={handleshowcarehomeEmailsDetailsModal}
              >
                Edit
              </NavLink>
            </h5>
            <ul style={{ fontSize: "15px" }}>
              {/* <li>
                Invoice Sent From (Email):{" "}
                {careHomeInvoiceDetail?.emails &&
                  careHomeInvoiceDetail?.emails?.invoiceSentFrom &&
                  careHomeInvoiceDetail?.emails?.invoiceSentFrom}
              </li> */}
              <li>Invoice Email (Cc): {invoiceEmailCc}</li>
              <li>Invoice Email (Bcc): {invoiceEmailBcc}</li>
              <li>Payment Receipt Email (Cc): {paymentReceiptEmailCc}</li>
              <li>Payment Receipt Email (Bcc): {paymentReceiptEmailBcc}</li>
            </ul>
          </div>
        </div>
        {/* <h4 className="card-title"></h4> */}
        <div>
          <DataTable
            searchText={searchText}
            canEditInvProfile={canEditInvProfile}
          />
        </div>
      </div>
      {/* Baisc Care Home Invoicing Details Edit Popup */}

      <Modal
        className="viewModal"
        show={showcarehomeDetailsModal}
        onHide={handleClosecarehomeDetailsModal}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Edit Care Home Invoice Details</span>
            <span>
              <AiOutlineClose
                className="curser_point"
                onClick={handleClosecarehomeDetailsModal}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="mb-1">Payment Deadline</label>
                <Select
                  defaultValue={selectedpaymentDeadline}
                  options={paymentDeadlineOptions}
                  onChange={paymentDeadlineHandleChange}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-1">
                  Invoice Prefix Numbering Format (max 5 character):
                </label>
                <input
                  type="text"
                  maxLength={5}
                  className="form-control"
                  value={invNumFormat}
                  onChange={(e) => {
                    setinvNumFormat(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-12 mb-3">
                <label className="mb-1">Tax Rate (%)</label>
                <input
                  type="number"
                  className="form-control"
                  value={taxRate}
                  onChange={(e) => {
                    setTaxRate(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-1">
                  Pay-As-You-Go Invoice Generation Trigger
                </label>
                <Select
                  defaultValue={selectedpayasyougooptions}
                  options={payasyougooptions}
                  onChange={payAsYouGoHandleChange}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              className="btn card-btn w-100"
              type="submit"
              disabled={loading === "basic"}
              onClick={() => {
                editCareHomeInvoiceDetails("basic");
              }}
            >
              Save
              {/* {loading === 'basic' ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `Save`} */}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Care Home Invoicing Emails Details Edit Popup Starts*/}
      <Modal
        className="viewModal"
        show={showcarehomeEmailsDetailsModal}
        onHide={handleClosecarehomeEmailsDetailsModal}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Edit Care Home Invoice Details</span>
            <span>
              <AiOutlineClose
                className="curser_point"
                onClick={handleClosecarehomeEmailsDetailsModal}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="mb-1">Invoice Email (Cc)</label>
                <input
                  type="email"
                  className="form-control"
                  value={invoiceEmailCc}
                  onChange={(e) => {
                    setInvoiceEmailCc(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-1">Invoice Email (Bcc)</label>
                <input
                  type="email"
                  className="form-control"
                  value={invoiceEmailBcc}
                  onChange={(e) => {
                    setInvoiceEmailBcc(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-1">Payment Receipt Email (Cc)</label>
                <input
                  type="email"
                  className="form-control"
                  value={paymentReceiptEmailCc}
                  onChange={(e) => {
                    setPaymentReceiptEmailCc(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-1">Payment Receipt Email (Bcc)</label>
                <input
                  type="email"
                  className="form-control"
                  value={paymentReceiptEmailBcc}
                  onChange={(e) => {
                    setPaymentReceiptEmailBcc(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              type="button"
              className="btn"
              onClick={() => {
                editCareHomeInvoiceDetails("emails");
              }}
            >
              {" "}
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Care Home Invoicing Emails Details Edit Popup Ends */}
      <Modal className="viewModal" show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Invoice</span>
            <span>
              <AiOutlineClose className="curser_point" onClick={handleClose} />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 mb-3">
              <img src="/images/icon_view.svg" art="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Your Company Name"
              />
              <textarea
                className="form-control mb-3"
                placeholder="Your Company Address"
              ></textarea>
              <input
                type="email"
                className="form-control mb-3"
                placeholder="Your Company Email"
              />
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Your Company Telephone"
              />
              <label className="text-dark mb-2">Bank Details</label>
              <textarea
                className="form-control mb-3"
                placeholder="Your Bank Details"
              ></textarea>
            </div>
            <div className="col-md-4"></div>

            <div className="col-md-4">
              <h6 className="text-end">To</h6>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Recipent Name"
              />
              <textarea
                className="form-control mb-3"
                placeholder="Recipent Address"
              ></textarea>
              <input
                type="email"
                className="form-control mb-3"
                placeholder="Recipent Email"
              />
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Recipent Telephone"
              />
              <ul className="invoice_list text-dark">
                <li>
                  <span>Invoice No.</span>
                  <span>Invoice Date</span>
                </li>
                <li>
                  <span></span>
                  <span>Invoice Deadline</span>
                </li>
              </ul>
            </div>
            <p className="mt-4"></p>
            <div className="col-md-6">
              <label className="text-dark mb-2">Description </label>
              <input type="text" className="form-control mb-3" placeholder="" />
            </div>

            <div className="col-md-2">
              <label className="text-dark mb-2">Period</label>
              <input type="text" className="form-control mb-3" placeholder="" />
            </div>

            <div className="col-md-2">
              <label className="text-dark mb-2">Rate </label>
              <input type="text" className="form-control mb-3" placeholder="" />
            </div>

            <div className="col-md-2">
              <label className="text-dark mb-2">Amount </label>
              <input type="text" className="form-control mb-3" placeholder="" />
            </div>
            <div className="col-md-9"></div>
            <div className="col-md-3 mt-5">
              <label className="text-dark mb-2">Total </label>
              <input type="text" className="form-control mb-3" placeholder="" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button className="btn">Edit</button>
            <button className="btn">Save</button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal className="viewModal" show={show3} onHide={handleClose3}>
        <Modal.Header closeButton3>
          <Modal.Title>
            <span>Edit Invoice Profile</span>
            <span>
              <AiOutlineClose className="curser_point" onClick={handleClose3} />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="mb-1">Select Resident</label>
              <select className="form-select">
                <option>Select Resident</option>
              </select>
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Invoice Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Fee Period</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Rate </label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Funding Source</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Invoice To</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Email</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Autogenerate Date</label>
              <input type="date" className="form-control" />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-1">Invoice Frequency</label>
              <input type="text" className="form-control" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button className="btn">Save</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
