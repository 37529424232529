import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import swal from "sweetalert";
import Select from "react-select";
import { httpAdmin } from "../../Apis/commonApis";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import {
  countryList,
  MedicalConditionOptions,
} from "../../constants/constants";
import { Controller, useForm } from "react-hook-form";
import { AiFillPlusCircle } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import { addCarePlanTemplate } from "../../redux-toolkit/reducer/carePlanReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  compressImage,
  formatDateToDDMMYYYY,
  getChangedKeysVals,
  getLabelValArray,
} from "../../utils/CommonFunctions";
import AwsLocation from "../../utils/AwsLocation";
import { ShowInfoSwal, ShowSwal } from "../../utils/alertSwal";
import CareMagnusTable from "../../utils/CareMagnusTable";
import ClientGpData from "./ClientGpData";
import {
  addressType,
  ethnicity,
  firstLanguage,
  religions,
  sexualOrientation,
} from "./profileConstant";

const chatUserImg = "/images/chatUser.svg";

const EditClients = (props) => {
  const { currentCareSiteData } = useSelector(
    (state) => state.caresiteDataReducer
  );
  const { clientAllData, Profileaccess, clientId, getClientbyId } = props;
  const {
    register,
    handleSubmit,
    reset,
    control: mediControl,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: editForm,
    handleSubmit: handleEditSubmit,
    reset: editFormReset,
    setValue: setProfileValue,
    control,
    watch,
    formState: { errors: editFormerrors },
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [editClientProfileData, setEditClientProfileData] = useState([]);
  const [changeBtn, setchangeBtn] = useState(true);
  const [editlocation, seteditlocation] = useState(null);
  const [editpermanentAddress, seteditpermanentAddress] = useState(null);
  const [advanceDirective, setAdvanceDirective] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [gender, setGender] = useState("");
  const [marital_Status, setMaritalStatus] = useState("");
  const [consentMentalCapacity, setconsentMentalCapacity] = useState("");
  const [consentPOA, setconsentPOA] = useState("");
  const [authrisedPerson, setauthrisedPerson] = useState([]);
  const [imgLoader, setImgLoader] = useState(false);
  const [editMedConId, setEditMedConId] = useState("");

  useEffect(() => {
    if (clientAllData) {
      setClientDataFunc(clientAllData);
    }
  }, [clientAllData]);

  function awsLocationHandler(locVal) {
    console.log(locVal, "llllllllllllll");
    seteditlocation(locVal);
    if (locVal?.uprn) {
      setProfileValue("systemUPRN", locVal.uprn);
    }
  }

  function permanentLocHandler(locVal) {
    seteditpermanentAddress(locVal);
  }

  const editClient = async (postData) => {
    let formdata = {
      ...postData,
      ethnicity: postData?.ethnicity?.value || "",
      firstLanguage: postData?.firstLanguage?.value || "",
      otherLanguage: postData?.otherLanguage?.value || "",
      sexualOrientation: postData?.sexualOrientation?.value || "",
      religion: postData?.religion?.value || "",
      citizenship: postData?.citizenship?.value || "",
    };
    if (marital_Status) formdata.marital_Status = marital_Status;
    if (bloodGroup) formdata.bloodGroup = bloodGroup;
    if (gender) formdata.gender = gender;
    console.log(editlocation, "eeeeeeeeeeee");
    if (editlocation) {
      formdata.location = editlocation?.Label;
      formdata.lattitude = editlocation?.latitude;
      formdata.longitude = editlocation?.longitude;
      formdata.systemUPRN = editlocation?.uprn;
    }
    if (editpermanentAddress) {
      formdata.permanentAddress = editpermanentAddress?.Label;
    }
    if (advanceDirective) formdata.advance_Directive = advanceDirective;
    if (consentMentalCapacity)
      formdata.consentMentalCapacity = consentMentalCapacity;
    if (consentPOA) formdata.consentPOA = consentPOA;
    let editData = getChangedKeysVals(editClientProfileData, formdata);
    setchangeBtn(true);
    if (Object.keys(editData)?.length < 1) {
      setchangeBtn(true);
      return;
    }
    const res = await httpAdmin(
      `edit_client/${clientId}`,
      "post",
      editData,
      null
    );
    if (res.status) {
      setClientDataFunc(res.data);
      getClientbyId();
      setchangeBtn(true);
    }
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  };

  function medicdocEvent(id) {
    const medCondData = getMediCond.find((mid) => mid._id == id);
    setValue("name", medCondData?.name);
    setValue("type", { label: medCondData?.type, value: medCondData?.type });
    setValue("start_Date", medCondData?.start_Date);
    setValue("diagnosedBy", medCondData?.diagnosedBy);
    setEditMedConId(medCondData._id);
    setIsOpen(true);
  }

  const [getMediCond, setGetMedicond] = useState([]);

  const mediCondition = async (formVals) => {
    let body = {
      ...formVals,
      type: formVals.type.label,
    };
    let apiEnd = "mediCondition";
    if (editMedConId !== "") {
      body.medic_id = editMedConId;
      apiEnd = "editmediCondition";
    }
    const res = await httpAdmin(`${apiEnd}/${clientId}`, "post", body, null);
    if (res.status) {
      handleCloseAddMedConditionPopup();
      getClientbyId();
    }
    ShowSwal(res.status, res.message ?? "Something went wrong!");
  };

  function handleCloseAddMedConditionPopup() {
    reset();
    setEditMedConId("");
    setIsOpen(false);
  }

  function setClientDataFunc(ClientData) {
    setEditClientProfileData(ClientData);
    setGetMedicond(
      ClientData?.medical_Conditions ? ClientData?.medical_Conditions : []
    );
    // Displaying Single Client data
    dispatch(addCarePlanTemplate({ firstName: ClientData?.first_Name }));
    setconsentMentalCapacity(ClientData?.consentMentalCapacity);
    setconsentPOA(ClientData?.consentPOA);
    setauthrisedPerson(ClientData?.authrisedPerson);
    setAdvanceDirective(ClientData?.advance_Directive);
    setBloodGroup(ClientData?.bloodGroup);
    setGender(ClientData?.gender);
    setMaritalStatus(ClientData?.marital_Status);
  }

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  const [selectedRow, setSelectedRow] = useState([]);

  // ---------------------     delete multiple  api    ----------------------------------  //

  const deletemultiple = async () => {
    const body = { clientId: clientId, medicalCondId: selectedRow };
    const res = await httpAdmin(`deleteMedicalCond`, "post", body, null);
    handleCloseDeletePopup();
    if (res.status) {
      handleCloseAddMedConditionPopup();
      getClientbyId();
    }
    ShowSwal(res.status, res.message ?? "Something went wrong!");
  };

  async function onFileChange(e) {
    let profileImg = e.target.files;
    if (profileImg) {
      setImgLoader(true);
      let compressedImg = await compressImage(profileImg);
      editImage(compressedImg);
    }
  }

  const editImage = async (imgg) => {
    let body = new FormData();
    body.append("image", imgg);
    const res = await httpAdmin(
      `edit_client/${clientId}`,
      "post",
      body,
      "formdata"
    );
    if (res.status) {
      let ClientData = res.data;
      setClientDataFunc(ClientData);
      setImgLoader(false);
    }
  };

  const handleRowSelection = (rowId) => {
    setSelectedRow(rowId.slice(-1));
  };
  const handleSelect = (value) => {
    if (!value) {
      return null;
    }
    return { label: value, value };
  };
  function handleEdit() {
    setProfileValue(
      "ethnicity",
      handleSelect(editClientProfileData?.ethnicity)
    );
    setProfileValue(
      "firstLanguage",
      handleSelect(editClientProfileData?.firstLanguage)
    );
    setProfileValue(
      "otherLanguage",
      handleSelect(editClientProfileData?.otherLanguage)
    );
    setProfileValue(
      "sexualOrientation",
      handleSelect(editClientProfileData?.sexualOrientation)
    );
    setProfileValue("religion", handleSelect(editClientProfileData?.religion));
  }

  return (
    <>
      <div
        id="profilePage"
        className="row"
        style={{ height: "78vh", overflowY: "auto" }}
      >
        <div className="col-md-6 pe-3">
          <div className="card_inbox">
            <div className="view_details position-relative">
              {imgLoader ? (
                <CircularProgress color="secondary" />
              ) : (
                <img
                  src={editClientProfileData?.image ?? chatUserImg}
                  className="profile_img"
                  alt="user"
                />
              )}
              <div className="edit_imgicon">
                <input
                  type="file"
                  id="upLoader"
                  accept="image/*"
                  name="images"
                  onChange={onFileChange}
                />
                <span>
                  <AiFillPlusCircle className="edit_icon" />
                </span>
              </div>
              <div className="float-end btns_head mb-3">
                {changeBtn ? (
                  <button
                    className="btn btn-theme btn-sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setchangeBtn(false);
                      handleEdit();
                    }}
                    disabled={Profileaccess !== "full"}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="btn btn-theme btn-sm"
                    type="submit"
                    onClick={handleEditSubmit(editClient)}
                  >
                    Save
                  </button>
                )}
              </div>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>First Name</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.first_Name}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.first_Name}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("first_Name")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.last_Name}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.last_Name}
                          className="form-control form-control-sm"
                          {...editForm("last_Name")}
                        />
                      </td>
                    )}
                  </tr>
                  {/* <tr>
                    <td>Surname</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.familyName}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.familyName}
                          className="form-control form-control-sm"
                          {...editForm("familyName")}
                        />
                      </td>
                    )}
                  </tr> */}
                  <tr>
                    <td>Prefered Name</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.preferredName}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.preferredName}
                          className="form-control form-control-sm"
                          {...editForm("preferredName")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Pronoun</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.pronoun}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          name="pronoun"
                          defaultValue={editClientProfileData?.pronoun}
                          className="form-control form-control-sm"
                          {...editForm("pronoun")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Date of Birth</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.DOB}</td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          name="DOB"
                          defaultValue={editClientProfileData?.DOB}
                          className="form-control form-control-sm"
                          {...editForm("DOB")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>NHS Number</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.NHS_number}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          name="NHS_number"
                          defaultValue={editClientProfileData?.NHS_number}
                          className="form-control form-control-sm"
                          {...editForm("NHS_number")}
                        />
                      </td>
                    )}
                  </tr>
                  {/* <tr>
                    <td>Given Name</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.givenName}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.givenName}
                          className="form-control form-control-sm"
                          {...editForm("givenName")}
                        />
                      </td>
                    )}
                  </tr> */}
                  <tr>
                    <td>Gender</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.gender}</td>
                    ) : (
                      <td>
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.gender,
                            label: editClientProfileData?.gender,
                          }}
                          options={[
                            { value: "Male", label: "Male" },
                            { value: "Female", label: "Female" },
                            { value: "Other", label: "Other" },
                          ]}
                          onChange={(event) => {
                            setGender(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Sexual Orientation</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.sexualOrientation}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="sexualOrientation"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={sexualOrientation}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.legelStatusOnAdmission?.message ||
                            errors.legelStatusOnAdmission?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Contract Start Date</td>
                    {changeBtn ? (
                      <td>
                        {formatDateToDDMMYYYY(
                          editClientProfileData?.contractStartdate
                        )}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.contractStartdate
                          }
                          // onChange={(e) => setContractStartdate(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("contractStartdate")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Contract End Date</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.contractEnddate}</td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={editClientProfileData?.contractEnddate}
                          // onChange={(e) => setContractEnddate(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("contractEnddate")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Blood Group</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.bloodGroup}</td>
                    ) : (
                      <td>
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.bloodGroup,
                            label: editClientProfileData?.bloodGroup,
                          }}
                          options={[
                            { value: "O+", label: "O+" },
                            { value: "O-", label: "O-" },
                            { value: "A+", label: "A+" },
                            { value: "A-", label: "A-" },
                            { value: "B+", label: "B+" },
                            { value: "B-", label: "B-" },
                            { value: "AB+", label: "AB+" },
                            { value: "AB-", label: "AB-" },
                          ]}
                          onChange={(event) => {
                            setBloodGroup(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Profession</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.profession}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.profession}
                          // onChange={(e) => setProfession(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("profession")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Care Provider</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.careProvider}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.careProvider}
                          // onChange={(e) => setProfession(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("careProvider")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Marital Status</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.marital_Status}</td>
                    ) : (
                      <td>
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.marital_Status,
                            label: editClientProfileData?.marital_Status,
                          }}
                          options={[
                            { value: "Single", label: "Single" },
                            { value: "Married", label: "Married" },
                            {
                              value: "Divorced",
                              label: "Divorced",
                            },
                            {
                              value: "Widowed",
                              label: "Widowed",
                            },
                            {
                              value: "Separated",
                              label: "Separated",
                            },
                            {
                              value: "Not known",
                              label: "Not known",
                            },
                            {
                              value: "Not applicable",
                              label: "Not applicable",
                            },
                          ]}
                          onChange={(event) => {
                            setMaritalStatus(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Religion</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.religion}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="religion"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={religions}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Ethnicity</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.ethnicity}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="ethnicity"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={ethnicity}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.legelStatusOnAdmission?.message ||
                            errors.legelStatusOnAdmission?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Citizenship</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.citizenship}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="citizenship"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={getLabelValArray(countryList, "name")}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  {/* <tr>
                    <td>Language</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.language}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.language}
                          className="form-control form-control-sm"
                          {...editForm("language")}
                        />
                      </td>
                    )}
                  </tr> */}
                  <tr>
                    <td>First Language</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.firstLanguage}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="firstLanguage"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={firstLanguage}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.legelStatusOnAdmission?.message ||
                            errors.legelStatusOnAdmission?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Other Language</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.otherLanguage}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="otherLanguage"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={firstLanguage}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.legelStatusOnAdmission?.message ||
                            errors.legelStatusOnAdmission?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Immigration Status</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.immigrationStatus}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.immigrationStatus
                          }
                          className="form-control form-control-sm"
                          {...editForm("immigrationStatus")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Interpreter Required</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.interpreterRequired}</td>
                    ) : (
                      <td>
                        <select
                          className="form-control form-control-sm"
                          {...editForm("interpreterRequired")}
                          defaultValue={
                            editClientProfileData?.interpreterRequired
                          }
                        >
                          <option value="">--Select--</option>
                          {[
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" },
                          ].map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Pregnancy Status</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.pregnancyStatus}</td>
                    ) : (
                      <td>
                        <select
                          className="form-control form-control-sm"
                          {...editForm("pregnancyStatus")}
                          defaultValue={editClientProfileData?.pregnancyStatus}
                        >
                          <option value="">--Select--</option>
                          {[
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" },
                            {
                              label:
                                "Not stated (PERSON asked but declined to provide a response)",
                              value:
                                "Not stated (PERSON asked but declined to provide a response)",
                            },
                            { label: "Unknown", value: "Unknown" },
                          ].map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>

              <h5 className="tb_title">Physical Appearance</h5>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>Height </td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.height}{" "}
                        {editClientProfileData?.height ? "cm" : ""}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.height}
                          // onChange={(e) => setHeight(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("height")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Weight </td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.weight}{" "}
                        {editClientProfileData?.weight ? "kg" : ""}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="number"
                          defaultValue={editClientProfileData?.weight}
                          // onChange={(e) => setWeight(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("weight")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Eye Color</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.eye_Color}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.eye_Color}
                          // onChange={(e) => setEyeColor(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("eye_Color")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Hair Color</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.hair_Color}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.hair_Color}
                          // onChange={(e) => setHairColor(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("hair_Color")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Distinguishing Mark</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.distinguishing_Mark}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.distinguishing_Mark
                          }
                          className="form-control form-control-sm"
                          {...editForm("distinguishing_Mark")}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>

              {/* <h5 className="tb_title">Brief Background</h5>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    {changeBtn ? (
                      <td>{editClientProfileData?.brief}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.brief}
                          // onChange={(e) => setBrief(e.target.value)}
                          className="form-control form-control-sm"
                          {...editForm("brief")}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
        </div>

        <div className="col-md-6 ps-3">
          <div className="mb-4 card_inbox">
            <div className="view_details">
              <h5 className="tb_title">Address</h5>
              <table className="table table-striped table_half">
                <tbody>
                  {currentCareSiteData?.carefacility != "agency" && (
                    <tr>
                      <td>Room No.</td>
                      {changeBtn ? (
                        <td>{editClientProfileData?.roomNo}</td>
                      ) : (
                        <td>
                          <input
                            type="number"
                            defaultValue={editClientProfileData?.roomNo}
                            className="form-control form-control-sm"
                            {...editForm("roomNo")}
                          />
                        </td>
                      )}
                    </tr>
                  )}

                  <tr>
                    <td>Current Address (Location)</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.location}</td>
                    ) : (
                      <td>
                        <AwsLocation
                          awsLocationHandler={awsLocationHandler}
                          defaultVal={editClientProfileData?.location}
                          setValue={setProfileValue}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>UPRN</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.systemUPRN || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.systemUPRN}
                          className="form-control form-control-sm"
                          {...editForm("systemUPRN")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Address association type</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.addressAssociationType || "--"}
                      </td>
                    ) : (
                      <td>
                        <select
                          className="form-control form-control-sm"
                          {...editForm("addressAssociationType")}
                          defaultValue={
                            editClientProfileData?.addressAssociationType
                          }
                        >
                          <option value="">--Select--</option>
                          {addressType.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Address association start date</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.addressAssociationStartDate ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.addressAssociationStartDate
                          }
                          className="form-control form-control-sm"
                          {...editForm("addressAssociationStartDate")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Address association end date</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.addressAssociationEndDate ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.addressAssociationEndDate
                          }
                          className="form-control form-control-sm"
                          {...editForm("addressAssociationEndDate")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Permanent Address</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.permanentAddress}</td>
                    ) : (
                      <td>
                        <AwsLocation
                          awsLocationHandler={permanentLocHandler}
                          defaultVal={editClientProfileData?.permanentAddress}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Currently Resident</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.currentlyResidence}</td>
                    ) : (
                      <td>
                        <select
                          className="form-control form-control-sm"
                          {...editForm("currentlyResidence")}
                          defaultValue={
                            editClientProfileData?.currentlyResidence
                          }
                        >
                          <option value="">--Select--</option>
                          {[
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" },
                          ].map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              {/* <h5 className="tb_title d-block">Consent</h5>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>Mental Capacity to Consent </td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.consentMentalCapacity}</td>
                    ) : (
                      <td>
                        
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.consentMentalCapacity,
                            label: editClientProfileData?.consentMentalCapacity,
                          }}
                          options={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                          ]}
                          onChange={(event) => {
                            setconsentMentalCapacity(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Power of Attorney in Place</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.consentPOA}</td>
                    ) : (
                      <td>
                        
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.consentPOA,
                            label: editClientProfileData?.consentPOA,
                          }}
                          options={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                          ]}
                          onChange={(event) => {
                            setconsentPOA(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Authorized Person</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.authrisedPerson?.map(
                          ({ name }) => {
                            return (
                              <>
                                <span key={name._id}>{name}</span>
                                <br />
                              </>
                            );
                          }
                        )}
                      </td>
                    ) : (
                      <td>
                        {editClientProfileData?.authrisedPerson?.map(
                          ({ name }) => {
                            return (
                              <>
                                <span key={name._id}>{name}</span>
                                <br />
                              </>
                            );
                          }
                        )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Advanced Directive</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.advance_Directive}</td>
                    ) : (
                      <td>
                        
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.advance_Directive,
                            label: editClientProfileData?.advance_Directive,
                          }}
                          options={[
                            { value: "CPR", label: "CPR" },
                            { value: "DNACPR", label: "DNACPR" },
                          ]}
                          onChange={(event) => {
                            setAdvanceDirective(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
          <ClientGpData
            Profileaccess={Profileaccess}
            clientId={clientId}
            key={clientId}
          />
          <div className="card_inbox mt-3">
            {/* sShowing Medical Condition */}
            <div className="mb-4 btns_head">
              <h5 className="tb_title mt-0 d-flex justify-content-between">
                Medical Conditions{" "}
                <DropdownButton id="dropdown-basic-button" title="Actions">
                  <Dropdown.Item
                    onClick={() => setIsOpen(true)}
                    disabled={Profileaccess !== "full"}
                  >
                    Add
                  </Dropdown.Item>
                  <Dropdown.Item
                    // className="action_table_btn"
                    onClick={() => {
                      if (selectedRow?.length > 0) {
                        medicdocEvent(selectedRow[0]);
                      } else {
                        ShowInfoSwal("Please select checkbox to edit!");
                      }
                    }}
                    disabled={Profileaccess !== "full"}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      if (selectedRow?.length > 0) {
                        handleShowDeletePopup(true);
                      } else {
                        ShowInfoSwal("Please select checkbox to delete!");
                      }
                    }}
                    disabled={Profileaccess !== "full"}
                  >
                    Delete{" "}
                  </Dropdown.Item>
                </DropdownButton>
              </h5>
            </div>

            <Box sx={{ height: 300, width: "100%" }}>
              <CareMagnusTable
                tableHeight="300px"
                idParam="_id"
                columns={columns}
                rows={getMediCond}
                selectedRow={selectedRow}
                handleRowSelection={handleRowSelection}
              />
            </Box>

            {/* Add Medical Condition */}
            <Modal
              show={isOpen}
              onHide={() => {
                reset();
                setIsOpen(false);
              }}
            >
              <Modal.Body>
                <div
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <form onSubmit={handleSubmit(mediCondition)}>
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            {editMedConId === "" ? "Add" : "Edit"} Medical
                            Condition
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleCloseAddMedConditionPopup}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("name", {
                                  required: true,
                                })}
                                name="name"
                              />
                              {errors?.name?.type === "required" && (
                                <p className="error_style">
                                  This field is required
                                </p>
                              )}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">Type</label>
                              {/* React Select Along with React Hook Form */}
                              <Controller
                                control={mediControl}
                                name="type"
                                rules={{
                                  required: "Type is required",
                                }}
                                render={({ field }) => {
                                  return (
                                    <Select
                                      {...field}
                                      isClearable
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={MedicalConditionOptions}
                                      // onChange={handleStatusChange}
                                    />
                                  );
                                }}
                              />
                              <p className="error_style">
                                {errors.type?.message ||
                                  errors.type?.label.message}
                              </p>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">Start Date</label>
                              <input
                                type="date"
                                className="form-control"
                                {...register("start_Date", {
                                  required: false,
                                })}
                                name="start_Date"
                              />
                              {errors?.start_Date?.type === "required" && (
                                <p className="error_style">
                                  This field is required
                                </p>
                              )}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">Diagnosed By</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("diagnosedBy", {
                                  required: false,
                                })}
                                name="diagnosedBy"
                              />
                              {errors?.diagnosedBy?.type === "required" && (
                                <p className="error_style">
                                  This field is required
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-success">
                            {editMedConId === "" ? "Create" : "Save"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              className="viewModal"
              show={isDeleteOpen}
              onHide={handleCloseDeletePopup}
            >
              <Modal.Header closeButton2>
                <Modal.Title>
                  <span>Delete medical condition</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <p>Are you sure you want to delete this entry ?</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <button
                    className="btn submitBtn"
                    onClick={handleCloseDeletePopup}
                  >
                    No
                  </button>
                  <button
                    className="btn submitBtn"
                    onClick={() => {
                      deletemultiple();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

const columns = [
  { field: "name", headerName: "Name", width: 90 },
  {
    field: "type",
    headerName: "Type",
    width: 120,
    editable: true,
  },
  {
    field: "start_Date",
    headerName: "Start Date",
    width: 120,
    editable: true,
  },
  { field: "diagnosedBy", headerName: "Diagnosed By", width: 120 },
];

export default EditClients;
