import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAdmin } from "../../Apis/commonApis";
import { currentCareSiteId } from "./switchSites";

const initialState = {
    loading: false,
    adminClients: [],
    adminCareTeamData: [],
    status: false,
};

export const fetchAdminClients = createAsyncThunk(
    "fetchAdminClients",
    async (query) => {
        const result = await httpAdmin(`clientbyId${query}`, "get", null, null);
        return result;
    }
);

export const fetchAdminCareTeamData = createAsyncThunk(
    "fetchAdminCareTeamData",
    async (query) => {
        const result = await httpAdmin(`careTeambyId${query}`, "get", null, null);
        return result;
    }
);

export const createAdminClient = createAsyncThunk(
    "createAdminClient",
    async (eventData) => {
        const careHomeId = localStorage.getItem("carehomeId");
        const userData = JSON.parse(localStorage.getItem("userData"));
        const data = {
            changeType: "Read",
            endpoint: window.location?.pathname,
            careHomeId: currentCareSiteId ?? careHomeId,
            reasonForAttestation: "-",
            // documentId: "673c2f7aacecdf82092151cb",
            userId: userData?._id,
            ...eventData
        };
        const result = await httpAdmin(`createEventLog`, "post", data, null);
        return result;
    }
);

const adminClientSlice = createSlice({
    name: "adminClientSlice",
    initialState,
    reducers: {
        resetAdminClientState(state = initialState, action) { },
    },
    extraReducers: {
        // fetch admin's client data
        [fetchAdminClients.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [fetchAdminClients.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.adminClients = payload.clientData ?? [];
        },
        // fetch admin's care team data
        [fetchAdminCareTeamData.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.adminCareTeamData = payload.careteamData ?? [];
        },
        [createAdminClient.fulfilled]: (state, { payload }) => {
            state.status = true;
        },
    },
});

export const { resetAdminClientState } = adminClientSlice.actions;
export default adminClientSlice.reducer;
