const Role = {
  OWNER: "superadmin",
  ADMIN: "admin",
  DEPUTY_ADMIN: "deputy_admin",
  CUSTOMER_SUPPORT: "customer_support",
};

export const Roles = [
  { value: 0, role: Role.OWNER, label: "Owner" },
  { value: 1, role: Role.ADMIN, label: "Admin" },
  { value: 2, role: Role.DEPUTY_ADMIN, label: "Deputy Admin" },
  { value: 3, role: Role.CUSTOMER_SUPPORT, label: "Customer Support" },
];

export const modules = [
  {
    value: "DashBoard",
    label: "DashBoard",
    link: "/superadmin/DashBoard",
    src: `${process.env.PUBLIC_URL}/images/si-1.svg`,
  },
  {
    value: "plans",
    label: "Products",
    link: "/superadmin/plans",
    src: `${process.env.PUBLIC_URL}/images/si-2.svg`,
  },
  {
    value: "client",
    label: "Clients",
    link: "/superadmin/clients",
    src: `${process.env.PUBLIC_URL}/images/si-3.svg`,
  },
  {
    value: "subscriptions",
    label: "Subscriptions",
    link: "/superadmin/subscription",
    src: `${process.env.PUBLIC_URL}/images/si-4.svg`,
  },
  {
    value: "invoicing",
    label: "Invoicing",
    link: "/superadmin/invoices",
    src: `${process.env.PUBLIC_URL}/images/si-6.svg`,
  },
  {
    value: "payments",
    label: "Payments",
    link: "/superadmin/payments",
    src: `${process.env.PUBLIC_URL}/images/si-7.svg`,
  },
  {
    value: "events",
    label: "All Events",
    link: "/superadmin/all-activity",
    src: `${process.env.PUBLIC_URL}/images/si-8.svg`,
  },
  // {
  //   value: "contentMgmt",
  //   label: "Content Mgmt.",
  //   link: "/superadmin/content-management",
  //   src: `${process.env.PUBLIC_URL}/images/si-9.svg`,
  // },
  {
    value: "reports",
    label: "Reports",
    link: "/superadmin/reports",
    src: `${process.env.PUBLIC_URL}/images/si-10.svg`,
  },
];

export const ClientOptions = [
  {
    value: "admin",
    heading: "Admin",
    isDisabled: true,
  },
  {
    value: "hrsOfService",
    label: "Hours of Service",
    subheading: "admin",
  },
  {
    value: "overdueTasks",
    label: "Overdue Tasks",
    subheading: "admin",
  },
  {
    value: "omittedTasks",
    label: "Omitted Tasks",
    subheading: "admin",
  },
  {
    value: "health",
    heading: "Health",
    isDisabled: true,
  },
  {
    value: "weight",
    label: "Weight (kg)",
    subheading: "health",
  },
  {
    value: "BP",
    label: "BP (mmHg)",
    subheading: "health",
  },
  {
    value: "Heart Rate",
    label: "Heart Rate (bpm)",
    subheading: "health",
  },
  {
    value: "Pulse Oximeter",
    label: "Pulse Oximeter (%SpO2)",
    subheading: "health",
  },
  {
    value: "Temperature",
    label: "Temperature (°C)",
    subheading: "health",
  },
  {
    value: "Respiratory Rate",
    label: "Respiratory Rate (bpm)",
    subheading: "health",
  },
  {
    value: "Blood Glucose",
    label: "Blood Glucose (mmol/L)",
    subheading: "health",
  },
  {
    value: "Daily Liquid Intake",
    label: "Liquid Intake (ml)",
    subheading: "health",
  },
  {
    value: "Daily Liquid Leakage",
    label: "Liquid Leakage (ml)",
    subheading: "health",
  },
  {
    value: "BMI",
    label: "BMI",
    subheading: "health",
  },
  {
    value: "Intake",
    label: "Food Intake (cal)",
    subheading: "health",
  },
  {
    value: "wellbeing",
    heading: "Wellbeing",
    isDisabled: true,
  },
  {
    value: "overallMood",
    label: "Overall Mood",
    subheading: "wellbeing",
  },
  {
    value: "sleep",
    label: "Sleep",
    subheading: "wellbeing",
  },
  {
    value: "pain",
    label: "pain",
    subheading: "wellbeing",
  },
  {
    value: "diet",
    label: "Diet",
    subheading: "wellbeing",
  },
  {
    value: "partcipation",
    label: "Participation",
    subheading: "wellbeing",
  },
  {
    value: "bowels",
    label: "Bowels",
    subheading: "wellbeing",
  },
  {
    value: "activity",
    label: "Activity",
    subheading: "wellbeing",
  },
];
export const CareTeamOptions = [
  {
    value: "weeklyHoursAssigned",
    label: "Weekly Hours Assigned",
    subheading: "careteam",
  },
  {
    value: "weeklyHoursWorked",
    label: "Weekly Hours Worked",
    subheading: "careteam",
  },
  {
    value: "mileage",
    label: "Mileage",
    subheading: "careteam",
  },
  {
    value: "overdueTasks",
    label: "No. of Overdue Tasks",
    subheading: "careteam",
  },
  {
    value: "omittedTasks",
    label: "No. of Omitted Tasks",
    subheading: "careteam",
  },
  {
    value: "shiftPunctualityRating",
    label: "Shift Punctuality",
    subheading: "careteam",
  },
  {
    value: "taskPunctualityRating",
    label: "Task Punctuality",
    subheading: "careteam",
  },
  {
    value: "fAndfRating",
    label: "Friends & Family Rating",
    subheading: "careteam",
  },
  {
    value: "performance",
    label: "Performance (%)",
    subheading: "careteam",
  },
];

export const wCategories = [
  { wCat: 'overallMood', displayName: 'Overall Mood' },
  { wCat: 'sleep', displayName: 'Sleep' },
  { wCat: 'pain', displayName: 'Pain' },
  { wCat: 'diet', displayName: 'Diet' },
  { wCat: 'partcipation', displayName: 'Partcipation' },
  { wCat: 'bowels', displayName: 'Bowels' },
  { wCat: 'activity', displayName: 'Activity' },
]

export const CarePlanOptions = [
  {
    value: 5,
    Ctg: "Social & Communication",
    apiCategoryName: "Social & Communications",
    image: `/images/s5.svg`,
  },
  {
    value: 1,
    Ctg: "Personal Care",
    apiCategoryName: "Personal Care",
    image: `/images/s1.svg`,
  },
  {
    value: 2,
    Ctg: "Health",
    apiCategoryName: "Health",
    image: `/images/s2.svg`,
  },
  {
    value: 3,
    Ctg: "Mobility & Safety",
    apiCategoryName: "Mobility&Safety",
    image: `/images/s3.svg`,
  },
  {
    value: 4,
    Ctg: "Diet",
    apiCategoryName: "Dietary",
    image: `/images/s4.svg`,
  },
  {
    value: 6,
    Ctg: "Housekeeping",
    image: `/images/housekeeping.svg`,
  },
  // {
  //   value: 7,
  //   Ctg: "Administrative",
  //   image: `/images/s12.svg`,
  // },
];

export const mainTasksArray = [
  { taskName: 'Personal Care', shortName: 'personalCare' },
  { taskName: 'Health', shortName: 'health' },
  { taskName: 'Mobility & Safety', shortName: 'mobility' },
  { taskName: 'Diet', shortName: 'diet' },
  { taskName: 'Social & Communication', shortName: 'social' },
  { taskName: 'PRN Meds', shortName: 'PRNMeds' },
  { taskName: 'Housekeeping', shortName: 'houseKeeping' },
  { taskName: 'Report Incident', shortName: 'reportIncident' },
]

export const taskCtgImages = [
  {
    value: 1,
    Ctg: "Personal Care",
    image: `/images/s1.svg`,
  },
  {
    value: 2,
    Ctg: "Health",
    image: `/images/s2.svg`,
  },
  {
    value: 3,
    Ctg: "Mobility",
    image: `/images/s3.svg`,
  },
  {
    value: 4,
    Ctg: "Diet",
    image: `/images/s4.svg`,
  },
  {
    value: 5,
    Ctg: "Social",
    image: `/images/s5.svg`,
  },
  {
    value: 6,
    Ctg: "PRN Meds",
    image: `/images/s6.svg`,
  },
  {
    value: 7,
    Ctg: "Housekeeping",
    image: `/images/s7.svg`,
  },
  {
    value: 8,
    Ctg: "Report Incident",
    image: `/images/s8.svg`,
  },
];

export const taskStatusColors = [
  { status: '', name: 'Unknown', bgColor: '#898988' },
  { status: 'false', name: 'Pending', bgColor: '#898988' },
  { status: 'In-Process', name: 'In-Process', bgColor: '#007fff' },
  { status: 'overdue', name: 'Overdue', bgColor: 'red' },
  { status: 'true', name: 'Completed', bgColor: 'green' },
  { status: 'omitted', name: 'Omitted', bgColor: '#F2C94C' },
  { status: 'self', name: 'Pending', bgColor: '#898988' },
  { status: 'assisted', name: 'Pending', bgColor: '#898988' },
  { status: 'assigned', name: 'Pending', bgColor: '#898988' },
  { status: 'prn', name: 'Pending', bgColor: '#898988' },
  { status: '', name: 'Unknown', bgColor: '#898988' },
  // { status: 'self', name: 'Self', bgColor: '#c58c85' },
  // { status: 'assisted', name: 'Assisted', bgColor: '#ff6347' },
  // { status: 'assigned', name: 'Assigned', bgColor: '#ba829c' },
  // { status: 'prn', name: 'PRN Med', bgColor: '#87CEEB' },
  // { status: '', name: 'Unknown', bgColor: '#898988' },
];

export const SuperAdminAccessManagementDefaultRoles = [
  {
    roleId: Role.OWNER,
    Modules: [
      {
        moduleName: "DashBoard",
        access: "full",
      },
      {
        moduleName: "Products",
        access: "full",
        children: [
          {
            moduleName: "Plans",
            access: "full",
          },
          {
            moduleName: "Add-Ons",
            access: "full",
          },
          {
            moduleName: "Coupons",
            access: "full",
          },
        ],
      },
      {
        moduleName: "Clients",
        access: "full",
      },
      {
        moduleName: "Subscriptions",
        access: "full",
      },
      {
        moduleName: "Invoicing",
        access: "full",
      },
      {
        moduleName: "Payments",
        access: "full",
      },
      {
        moduleName: "All Events",
        access: "full",
      },
      {
        moduleName: "Content Mgmt.",
        access: "full",
      },

      {
        moduleName: "Reports",
        access: "full",
        children: [
          {
            moduleName: "Revenue",
            access: "full",
          },
          {
            moduleName: "Subscriptions",
            access: "full",
          },
          {
            moduleName: "Clients",
            access: "full",
          },
          {
            moduleName: "Payments",
            access: "full",
          },
        ],
      },
    ],
  },
  {
    roleId: Role.ADMIN,
    Modules: [
      {
        moduleName: "DashBoard",
        access: "full",
      },
      {
        moduleName: "Products",
        access: "full",
        children: [
          {
            moduleName: "Plans",
            access: "full",
          },
          {
            moduleName: "Add-Ons",
            access: "full",
          },
          {
            moduleName: "Coupons",
            access: "full",
          },
        ],
      },
      {
        moduleName: "Clients",
        access: "full",
      },
      {
        moduleName: "Subscriptions",
        access: "full",
      },
      {
        moduleName: "Invoicing",
        access: "full",
      },
      {
        moduleName: "Payments",
        access: "full",
      },
      {
        moduleName: "All Events",
        access: "full",
      },
      {
        moduleName: "Content Mgmt.",
        access: "full",
      },

      {
        moduleName: "Reports",
        access: "full",
        children: [
          {
            moduleName: "Revenue",
            access: "full",
          },
          {
            moduleName: "Subscriptions",
            access: "full",
          },
          {
            moduleName: "Clients",
            access: "full",
          },
          {
            moduleName: "Payments",
            access: "full",
          },
        ],
      },
    ],
  },
  {
    roleId: Role.DEPUTY_ADMIN,
    Modules: [
      {
        moduleName: "DashBoard",
        access: "view",
      },
      {
        moduleName: "Products",
        access: "full",
        children: [
          {
            moduleName: "Plans",
            access: "view",
          },
          {
            moduleName: "Add-Ons",
            access: "view",
          },
          {
            moduleName: "Coupons",
            access: "view",
          },
        ],
      },
      {
        moduleName: "Clients",
        access: "view",
      },
      {
        moduleName: "Subscriptions",
        access: "view",
      },
      {
        moduleName: "Invoicing",
        access: "view",
      },
      {
        moduleName: "Payments",
        access: "view",
      },
      {
        moduleName: "All Events",
        access: "full",
      },
      {
        moduleName: "Content Mgmt.",
        access: "full",
      },

      {
        moduleName: "Reports",
        access: "full",
        children: [
          {
            moduleName: "Revenue",
            access: "no",
          },
          {
            moduleName: "Subscriptions",
            access: "view",
          },
          {
            moduleName: "Clients",
            access: "view",
          },
          {
            moduleName: "Payments",
            access: "no",
          },
        ],
      },
    ],
  },
  {
    roleId: Role.CUSTOMER_SUPPORT,
    Modules: [
      {
        moduleName: "DashBoard",
        access: "no",
      },
      {
        moduleName: "Products",
        access: "full",
        children: [
          {
            moduleName: "Plans",
            access: "view",
          },
          {
            moduleName: "Add-Ons",
            access: "view",
          },
          {
            moduleName: "Coupons",
            access: "no",
          },
        ],
      },
      {
        moduleName: "Clients",
        access: "view",
      },
      {
        moduleName: "Subscriptions",
        access: "view",
      },
      {
        moduleName: "Invoicing",
        access: "no",
      },
      {
        moduleName: "Payments",
        access: "no",
      },
      {
        moduleName: "All Events",
        access: "view",
      },
      {
        moduleName: "Content Mgmt.",
        access: "no",
      },
      {
        moduleName: "Reports",
        access: "full",
        children: [
          {
            moduleName: "Revenue",
            access: "no",
          },
          {
            moduleName: "Subscriptions",
            access: "view",
          },
          {
            moduleName: "Clients",
            access: "view",
          },
          {
            moduleName: "Payments",
            access: "no",
          },
        ],
      },
    ],
  },
];

const CareSiteType = {
  CARE_SITE_FACILITIES: "care_site_facilities",
  CARE_SITE_AGENCIES: "care_site_agency",
};

const PermissionAccess = {
  MODIFY_FILE: "MODIFY_FILE",
  VIEW_FILE: "VIEW_FILE",
  DELETE_FILE: "DELETE_FILE",
  CREATE_FILE: "CREATE_FILE",
};

const PlansPermission = {
  CARE_BASE_PLAN: "base_plan",
  CARE_ADVANCE_PLAN: "advance_plan",
  CARE_TURBO_PLAN: "turbo_plan",
};

const ModulePermission = {
  CARE_MODULE_DashBoard: "",
  CARE_MODULE_CLIENT: "",
  CARE_MODULE_CARETEAM: "",
  CARE_MODULE_SCHEDULER: "",
  CARE_MODULE_REPORTS: "",
  CARE_MODULE_MEDICINES: "",
  CARE_MODULE_INVOICING: "",
  CARE_MODULE_SALES_FUNNEL: "",
  CARE_MODULE_MESSAGING: "",
  CARE_MODULE_SETTINGS: "",
  CARE_MODULE_ACCESS_RIGHTS: "",
};

const ClientModule = {
  CARE_CLIENTS: {
    CREATE_GROUP: "",
    CREATE_CLIENT_DETAILS: "",
    VIEW_CLIENT_DETAILS: "",
    EDIT_CLIENT_DETAILS: "",
    DELETE_CLIENT_DETAILS: "",
    CLIENT_MEDICAL_COND_ADD_DOC: "",
    CLIENT_MEDICAL_COND_EDIT_DOC: "",
    CLIENT_MEDICAL_COND_VIEW_DOC: "",
    CLIENT_MEDICAL_COND_DELETE_DOC: "",
    CLIENT_CONTACT_FORM_ADD_DOC: "",
    CLIENT_CONTACT_FORM_EDIT_DOC: "",
    CLIENT_CONTACT_FORM_VIEW_DOC: "",
    CLIENT_CONTACT_FORM_DELETE_DOC: "",
    CLIENT_COMPLIANCE_ADD_DOC: "",
    CLIENT_COMPLIANCE_EDIT_DOC: "",
    CLIENT_COMPLIANCE_VIEW_DOC: "",
    CLIENT_COMPLIANCE_DELETE_DOC: "",
    STORE_CARE_PLAN: "",
    ADD_CARE_PLAN: "",
    DELETE_CARE_PLAN: "",
    EDIT_CARE_PLAN: "",
    VIEW_CARE_PLAN: "",
    CLIENT_JOURNAL_ACTIVITY: "",
    CLIENT_JOURNAL_CARETEAM_MEMBER_NOTES: "",
    CLIENT_JOURNAL_FRIENDS_AND_FAMILY_NOTES: "",
  },
};

const CareTeamModule = {
  CARE_CARETEAM: {
    CREATE_GROUP: "",
    ADD_CARETEAM_DETAILS: "",
    EDIT_CARETEAM_DETAILS: "",
    VIEW_CARETEAM_DETAILS: "",
    SHOW_GENERAL_AVAILABILITY: "",
    ADD_CARETEAM_DOCS: "",
    EDIT_CARETEAM_DOCS: "",
    VIEW_CARETEAM_DOCS: "",
    DELETE_CARETEAM_DOCS: "",
    ADD_CARETEAM_TRAINING_DETAILS: "",
    EDIT_CARETEAM_TRAINING_DETAILS: "",
    VIEW_CARETEAM_TRAINING_DETAILS: "",
    DELETE_CARETEAM_TRAINING_DETAILS: "",
    JOURNAL_NOTES: "",
    JOURNAL_ACTIVITY: "",
  },
};

const Scheduler = {
  TASK_STATUS: "",
  SCHEDULER: "",
  PAYROLL_CALCULATOR: "",
  CREATE_NEW_SHIFT: "",
  CREATE_NEW_TEMPLATE: {
    CLICK_TEMPLATES: "",
    GENERIC_TEMPLATE: "",
    CLIENT_SPECIFIC_TEMPLATE: "",
  },
  FORM_TEMPLATE: "",
  EDIT_TEMPLATE: "",
  DELETE_TEMPLATE: "",
  EDIT_SHIFT: "",
  DELETE_SHIFT: {
    CLICK: "",
    DELETE_SINGLE_SHIFT: "",
    DELETE_ALL_SHIFTS: "",
  },
  OPEN_SHIFT_FUNCTIONALITY: "",
  RECURRING_SHIFT_FUNCTIONALITY: "",
  ACCESS_CARETEAM_SHIFT_NOTES: "",
  CAPTURE_AND_ATTACH_PHOTOS_IN_TASK: "",
  CREATE_AND_COMPLETE_TASKS: "",
};

const Reports = {
  WELLBEING_CHARTS: "",
  PAYROLL_CHARTS: "",
  SEARCH_CARETEAM: "",
  SEARCH_CLIENT: "",
};

export const ComplianceDocsTypes = [
  { label: "--Select--", value: "" },
  { label: "Compliance", value: "Compliance" },
  { label: "Care-plan", value: "Care-plan" },
  // { label: "Petty-Cash", value: "Petty-Cash" },
  { label: "Consent", value: "Consent" },
  { label: "Medical Records", value: "Medical Records" },
  { label: "Financial", value: "Financial" },
  { label: "Legal", value: "Legal" },
  { label: "Visitors Log", value: "Visitors Log" },
  { label: "Admin", value: "Admin" },
  { label: "Miscellaneous", value: "Miscellaneous" },
];

export const ClientStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "closed" },
  // { label: "Moving Out", value: "moving_out" },
];
export const CareTeamStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const BooleanOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const MedicalConditionOptions = [
  { label: "Condition", value: "Condition" },
  { label: "Allergy", value: "Allergy" },
];

export const FriendsAndFamilyStatus = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const ShiftTaskStatus = [
  { label: "Completed", value: "true" },
  { label: "Pending", value: "false" },
  { label: "PRN Med", value: "prn" },
  { label: "Self", value: "self" },
  { label: "Assisted", value: "assisted" },
  { label: "Assigned", value: "assigned" },
  { label: "Overdue", value: "overdue" },
  { label: "In-Process", value: "In-Process" },
  { label: "Omitted", value: "omitted" },
];

export const CareTeamDocsTypes = [
  { label: "Qualification", value: "Qualification" },
  { label: "Verification", value: "Verification" },
  { label: "Application", value: "Application" },
  { label: "Notes", value: "Notes" },
  { label: "Miscellaneous", value: "Miscellaneous" },
];
export const CareTeamTrainingTypes = [
  { label: "Caregiver Training", value: "Caregiver Training" },
  { label: "First Aid", value: "First Aid" },
  { label: "Dementia", value: "Dementia" },
  { label: "Medication Management", value: "Medication Management" },
  { label: "Communications", value: "Communications" },
  { label: "Safety", value: "Safety" },
  { label: "Nutrition", value: "Nutrition" },
  { label: "End-of-Life", value: "End-of-Life" },
  { label: "Cultural & Diversity", value: "Cultural & Diversity" },
  { label: "Legal & Ethical", value: "Legal & Ethical" },
  { label: "Miscellaneous", value: "Miscellaneous" },
];

export const securityQuestions = [
  {
    label: "What is your favorite sports team?",
    value: "What is your favorite sports team?",
  },
  {
    label: "What was the name of your first pet?",
    value: "What was the name of your first pet?",
  },
  {
    label: "In which city were you born?",
    value: "In which city were you born?",
  },
  {
    label: "What is your favorite movie?",
    value: "What is your favorite movie?",
  },
  {
    label: "What is the name of your favorite teacher?",
    value: "What is the name of your favorite teacher?",
  },
  {
    label: "What was the make and model of your first car?",
    value: "What was the make and model of your first car?",
  },
  {
    label: "What is the name of your favorite childhood friend?",
    value: "What is the name of your favorite childhood friend?",
  },
];

export const timeValues = [
  { value: "00:00", label: "00 : 00" },
  { value: "00:15", label: "00 : 15" },
  { value: "00:30", label: "00 : 30" },
  { value: "00:45", label: "00 : 45" },
  { value: "01:00", label: "01 : 00" },
  { value: "01:15", label: "01 : 15" },
  { value: "01:30", label: "01 : 30" },
  { value: "01:45", label: "01 : 45" },
  { value: "02:00", label: "02 : 00" },
  { value: "02:15", label: "02 : 15" },
  { value: "02:30", label: "02 : 30" },
  { value: "02:45", label: "02 : 45" },
  { value: "03:00", label: "03 : 00" },
  { value: "03:15", label: "03 : 15" },
  { value: "03:30", label: "03 : 30" },
  { value: "03:45", label: "03 : 45" },
  { value: "04:00", label: "04 : 00" },
  { value: "04:15", label: "04 : 15" },
  { value: "04:30", label: "04 : 30" },
  { value: "04:45", label: "04 : 45" },
  { value: "05:00", label: "05 : 00" },
  { value: "05:15", label: "05 : 15" },
  { value: "05:30", label: "05 : 30" },
  { value: "05:45", label: "05 : 45" },
  { value: "06:00", label: "06 : 00" },
  { value: "06:15", label: "06 : 15" },
  { value: "06:30", label: "06 : 30" },
  { value: "06:45", label: "06 : 45" },
  { value: "07:00", label: "07 : 00" },
  { value: "07:15", label: "07 : 15" },
  { value: "07:30", label: "07 : 30" },
  { value: "07:45", label: "07 : 45" },
  { value: "08:00", label: "08 : 00" },
  { value: "08:15", label: "08 : 15" },
  { value: "08:30", label: "08 : 30" },
  { value: "08:45", label: "08 : 45" },
  { value: "09:00", label: "09 : 00" },
  { value: "09:15", label: "09 : 15" },
  { value: "09:30", label: "09 : 30" },
  { value: "09:45", label: "09 : 45" },
  { value: "10:00", label: "10 : 00" },
  { value: "10:15", label: "10 : 15" },
  { value: "10:30", label: "10 : 30" },
  { value: "10:45", label: "10 : 45" },
  { value: "11:00", label: "11 : 00" },
  { value: "11:15", label: "11 : 15" },
  { value: "11:30", label: "11 : 30" },
  { value: "11:45", label: "11 : 45" },
  { value: "12:00", label: "12 : 00" },
  { value: "12:15", label: "12 : 15" },
  { value: "12:30", label: "12 : 30" },
  { value: "12:45", label: "12 : 45" },
  { value: "13:00", label: "13 : 00" },
  { value: "13:15", label: "13 : 15" },
  { value: "13:30", label: "13 : 30" },
  { value: "13:45", label: "13 : 45" },
  { value: "14:00", label: "14 : 00" },
  { value: "14:15", label: "14 : 15" },
  { value: "14:30", label: "14 : 30" },
  { value: "14:45", label: "14 : 45" },
  { value: "15:00", label: "15 : 00" },
  { value: "15:15", label: "15 : 15" },
  { value: "15:30", label: "15 : 30" },
  { value: "15:45", label: "15 : 45" },
  { value: "16:00", label: "16 : 00" },
  { value: "16:15", label: "16 : 15" },
  { value: "16:30", label: "16 : 30" },
  { value: "16:45", label: "16 : 45" },
  { value: "17:00", label: "17 : 00" },
  { value: "17:15", label: "17 : 15" },
  { value: "17:30", label: "17 : 30" },
  { value: "17:45", label: "17 : 45" },
  { value: "18:00", label: "18 : 00" },
  { value: "18:15", label: "18 : 15" },
  { value: "18:30", label: "18 : 30" },
  { value: "18:45", label: "18 : 45" },
  { value: "19:00", label: "19 : 00" },
  { value: "19:15", label: "19 : 15" },
  { value: "19:30", label: "19 : 30" },
  { value: "19:45", label: "19 : 45" },
  { value: "20:00", label: "20 : 00" },
  { value: "20:15", label: "20 : 15" },
  { value: "20:30", label: "20 : 30" },
  { value: "20:45", label: "20 : 45" },
  { value: "21:00", label: "21 : 00" },
  { value: "21:15", label: "21 : 15" },
  { value: "21:30", label: "21 : 30" },
  { value: "21:45", label: "21 : 45" },
  { value: "22:00", label: "22 : 00" },
  { value: "22:15", label: "22 : 15" },
  { value: "22:30", label: "22 : 30" },
  { value: "22:45", label: "22 : 45" },
  { value: "23:00", label: "23 : 00" },
  { value: "23:15", label: "23 : 15" },
  { value: "23:30", label: "23 : 30" },
  { value: "23:45", label: "23 : 45" },
  { value: "24:00", label: "24 : 00" },
];

export const weekDays = [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
];

export const jobTitleOptions = [
  { value: "Carer", label: "Carer" },
  { value: "Manager", label: "Manager" },
  { value: "Supervisor", label: "Supervisor" },
  { value: "Cook", label: "Cook" },
  { value: "Kitchen Staff", label: "Kitchen Staff" },
  { value: "Housekeeping", label: "Housekeeping" },
];

export const colorCodes = ['#F2994A', '#828282', '#27AE60', 'red', 'blue', 'green', 'black', 'wine'];

export const maritalStatusOptions = [
  { value: "", label: "--Select--" },
  { value: "Married", label: "Married" },
  {
    value: "Widowed",
    label: "Widowed",
  },
  {
    value: "Separated",
    label: "Separated",
  },
  {
    value: "Divorced",
    label: "Divorced",
  },
  { value: "Single", label: "Single" },
];

export const countryList = [
  { name: "United Kingdom", code: "UK", url: "uk" },
  { name: "United States", code: "US", url: "us" },
  { name: "India", code: "IN", url: "india" },
  { name: "Canada", code: "CA", url: "canada" },
  { name: "Austria", code: "AT", url: "austria" },
  { name: "Belgium", code: "BE", url: "belgium" },
  { name: "Bulgaria", code: "BG", url: "bulgaria" },
  { name: "Croatia", code: "HR", url: "croatia" },
  { name: "Republic of Cyprus", code: "CY", url: "cyprus" },
  { name: "Czech Republic", code: "CZ", url: "czech" },
  { name: "Denmark", code: "DK", url: "denmark" },
  { name: "Estonia", code: "EE", url: "estonia" },
  { name: "Finland", code: "FI", url: "finland" },
  { name: "France", code: "FR", url: "france" },
  { name: "Germany", code: "DE", url: "germany" },
  { name: "Greece", code: "GR", url: "greece" },
  { name: "Hungary", code: "HU", url: "hungary" },
  { name: "Ireland", code: "IE", url: "ireland" },
  { name: "Italy", code: "IT", url: "italy" },
  { name: "Latvia", code: "LV", url: "latvia" },
  { name: "Lithuania", code: "LT", url: "lithuania" },
  { name: "Luxembourg", code: "LU", url: "luxembourg" },
  { name: "Malta", code: "MT", url: "malta" },
  { name: "Netherlands", code: "NL", url: "netherlands" },
  { name: "Poland", code: "PL", url: "poland" },
  { name: "Portugal", code: "PT", url: "portugal" },
  { name: "Romania", code: "RO", url: "romania" },
  { name: "Slovakia", code: "SK", url: "slovakia" },
  { name: "Slovenia", code: "SI", url: "slovenia" },
  { name: "Spain", code: "ES", url: "spain" },
  { name: "Sweden", code: "SE", url: "sweden" },
];

export const countryCodes = {
  Afghanistan: "AFG",
  Albania: "ALB",
  Algeria: "DZA",
  Andorra: "AND",
  Angola: "AGO",
  Argentina: "ARG",
  Australia: "AUS",
  Austria: "AUT",
  Bahrain: "BHR",
  Bangladesh: "BGD",
  Belgium: "BEL",
  Brazil: "BRA",
  Canada: "CAN",
  China: "CHN",
  Egypt: "EGY",
  France: "FRA",
  Germany: "DEU",
  India: "IND",
  Italy: "ITA",
  Japan: "JPN",
  Mexico: "MEX",
  Nigeria: "NGA",
  Russia: "RUS",
  '[South Africa]': "ZAF",
  Spain: "ESP",
  Switzerland: "CHE",
  ['United Kingdom']: "GBR",
  ['United States']: "USA",
};