import React, { useState, useEffect } from "react";
import "../CareTeam/careteam.css";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import CareteamRoutes from "./CareteamRoutes";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { exportToExcel } from "react-json-to-excel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminCareTeamData } from "../../redux-toolkit/reducer/admin_clients_reducer";

function CareteamDetails() {
  const history = useHistory();
  const location = useLocation();
  const careteamId = location.state?.careteamId;
  let { path, url } = useRouteMatch();

  const dispatch = useDispatch();
  const { adminCareTeamData } = useSelector(
    (state) => state.adminClientReducer
  );

  useEffect(() => {
    const queryString = `?careteamId=${location?.state?.careteamId}`;
    dispatch(fetchAdminCareTeamData(queryString));
  }, []);

  const handleNavigate = (type) => {
    history.replace({
      pathname: `${url}/${type}`,
      state: { careteamId: careteamId },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentUrl = location.pathname;

  const handleMenuClick = (type) => {
    handleClose();
    if (type === "excel") {
      const dataToExport = mapDataToExport(adminCareTeamData, currentUrl);
      exportToExcel(
        dataToExport.data,
        `${adminCareTeamData?.first_Name}_${dataToExport.fileName}_data`
      );
    } else {
      saveToDownloads();
    }
  };

  const isHideHamsburg =
    currentUrl.includes("docs") || currentUrl.includes("journal");
  const hamsburgHideStyle = isHideHamsburg
    ? { opacity: 0, cursor: "initial !important" }
    : {};

  function saveToDownloads() {
    let downloadPageName = "";

    if (currentUrl.includes("profile")) {
      downloadPageName = "Profile";
    } else {
      return;
    }

    const fileName = `${adminCareTeamData.first_Name}-${downloadPageName}-data`;
    const input = document.getElementById(`careteam${downloadPageName}Page`);
    html2canvas(input, { proxy: adminCareTeamData.image, scrollY: 0 })
      .then((canvas) => {
        const image = { type: "jpeg", quality: 0.98 };
        const margin = [0.6, 0.5];

        var imgWidth = 8.5;
        var pageHeight = 11;

        var innerPageWidth = imgWidth - margin[0] * 2;
        var innerPageHeight = pageHeight - margin[1] * 2;

        // Calculate the number of pages.
        var pxFullHeight = canvas.height;
        var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        var nPages = Math.ceil(pxFullHeight / pxPageHeight);

        // Define pageHeight separately so it can be trimmed on the final page.
        var pageHeight = innerPageHeight;

        // Create a one-page canvas to split up the full image.
        var pageCanvas = document.createElement("canvas");
        var pageCtx = pageCanvas.getContext("2d");
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        // Initialize the PDF.
        var pdf = new jsPDF("p", "in", [8.5, 11]);

        for (var page = 0; page < nPages; page++) {
          // Trim the final page to reduce file size.
          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight =
              (pageCanvas.height * innerPageWidth) / pageCanvas.width;
          }

          // Display the page.
          var w = pageCanvas.width;
          var h = pageCanvas.height;
          pageCtx.fillStyle = "white";
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          // Add the page to the PDF.
          if (page > 0) pdf.addPage();
          // debugger;
          var imgData = pageCanvas.toDataURL(
            "image/" + image.type,
            image.quality
          );
          pdf.addImage(
            imgData,
            image.type,
            margin[1],
            margin[0],
            innerPageWidth,
            pageHeight
          );
        }

        pdf.save(fileName);
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <div className="top_menubar">
                <ul
                  className="nav nav-tabs ract_tab_list border-0 listspaceGap align-items-center"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <p
                      onClick={() => handleNavigate("profile")}
                      className={`nav-link curser_point ${
                        window.location.href.includes("profile") ? "active" : ""
                      }`}
                    >
                      Profile
                    </p>
                  </li>

                  <li className="nav-item" role="presentation">
                    <p
                      onClick={() => handleNavigate("docs")}
                      className={`nav-link curser_point ${
                        window.location.href.includes("docs") ? "active" : ""
                      }`}
                    >
                      Docs
                    </p>
                  </li>

                  <li className={`nav-item`} role="presentation">
                    <p
                      onClick={() => handleNavigate("trainings")}
                      className={`nav-link curser_point ${
                        window.location.href.includes("trainings")
                          ? "active"
                          : ""
                      }`}
                    >
                      Trainings
                    </p>
                  </li>

                  <li className="nav-item" role="presentation">
                    <p
                      onClick={() => handleNavigate("journal")}
                      className={`nav-link curser_point ${
                        window.location.href.includes("journal") ? "active" : ""
                      }`}
                    >
                      Journal
                    </p>
                  </li>

                  <li
                    className="nav-item position-relative"
                    role="presentation"
                  >
                    <IconButton
                      disabled={isHideHamsburg}
                      onClick={handleClick}
                      style={hamsburgHideStyle}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          // boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                          border: "0.6px solid rgb(121 120 120 / 10%)",
                          borderRadius: "8px",
                          minWidth: "150px",
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleMenuClick("excel")}>
                        Download Excel
                      </MenuItem>
                      {!currentUrl?.includes("trainings") && (
                        <MenuItem onClick={() => handleMenuClick("pdf")}>
                          Download Pdf
                        </MenuItem>
                      )}
                    </Menu>
                  </li>
                </ul>
              </div>
              <div className="tab-content mt-3" id="myTabContent">
                <Switch>
                  <Route path={`${path}/:topicId`}>
                    <CareteamRoutes />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CareteamDetails;

function mapDataToExport(data, url) {
  if (url.includes("profile")) {
    return {
      fileName: "profile",
      data: [
        {
          "First Name": data?.first_Name ?? "",
          "Last Name": data?.last_Name ?? "",
          Gender: data?.gender ?? "",
          "Job Title": data?.jobTitle ?? "",
          Role: data?.Role ?? "",
          Speciality: data?.speciality ?? "",
          Qualification: data?.qualification ?? "",
          "Date of Birth": data?.DOB ?? "",
          Location: data?.location ?? "",
          "Contact Number": data?.mobileNo ?? "",
          Email: data?.email ?? "",
          "Marital Status": data?.marital_Status ?? "",
          Religion: data?.religion ?? "",
          Citizenship: data?.citizenship ?? "",
          Languages: data?.firstLanguage ?? "",
          "Employment End Date": data?.endDate ?? "",
          "Subject of care for Care-Worker":
            data?.subjectOfCareForCareWorker ?? "",
          "Key worker": data?.keyWorker ?? "",
          "Household member": data?.householdMember ?? "",
          "Carer relationship": data?.carerRelationShip ?? "",
          "Relationship description": data?.relatonshipDescription ?? "",
          "Care organisation": data?.careOrganisation ?? "",
          "Care team": data?.careTeam ?? "",
          "Weekly Normal Hours Threshold": data?.weeklyPlannedHours ?? "",
        },
      ],
    };
  } else if (url.includes("trainings")) {
    return {
      fileName: "trainings",
      data: data?.Tranining?.map((elem) => {
        return {
          Name: elem.trainingName,
          "Training Type": elem.type ?? "",
          "Training Date": elem.trainingDate ?? "",
          "Creation Date": elem.creationDate ?? "",
          "Created By": elem.createdBy ?? "",
          "Next Review": elem.review ?? "",
          File: elem.image ?? "",
        };
      }),
    };
  }
}
