import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import swal from "sweetalert";
import NoRowsOverlay from "../../components/NoRowsOverlays";
import { fetch4, httpAdmin } from "../../Apis/commonApis";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { AiFillPlusCircle } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import {
  countryList,
  jobTitleOptions,
  maritalStatusOptions,
  timeValues,
  weekDays,
} from "../../constants/constants";
import {
  compressImage,
  getChangedKeysVals,
  getLabelValArray,
} from "../../utils/CommonFunctions";
import PhoneInput from "react-phone-input-2";
import AwsLocation from "../../utils/AwsLocation";
import { ShowSwal } from "../../utils/alertSwal";
import { speciality, roles, careRelationship } from "./careTeamConstant";
import { firstLanguage, religions } from "../Clients/profileConstant";

const chatUserImg = "/images/chatUser.svg";
let editable = 1;

const columns = [
  {
    field: "year",
    headerName: "Year",
    width: 90,
  },
  {
    field: "fromDate",
    headerName: "From (date)",
    width: 150,
    editable: true,
  },
  {
    field: "toDate",
    headerName: "To (date)",
    width: 150,
    editable: true,
  },
  {
    field: "approvedBy",
    headerName: "Approved By",
    width: 150,
    editable: true,
  },
];

const EditCareTeam = (props) => {
  const { careteamId, Profileaccess } = props;

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const {
    register: registerS,
    handleSubmit: handleSubmitS,
    unregister: unregisterS,
    reset: resetS,
    formState: { errors: errorsS },
    control: controlS,
  } = useForm();

  const {
    register: editForm,
    handleSubmit: handleEditSubmit,
    reset: editFormReset,
    setValue,
    control: formControl,
    formState: { errors: editFormerrors },
  } = useForm();

  let [editFormData, setEditFormData] = useState({
    first_Name: "",
    isEditProduct: true,
  });
  let [changeBtn, setchangeBtn] = useState(true);
  let [location, setlocation] = useState(null);
  let [mobileNo, setmobileNo] = useState("");
  let [marital_Status, setmarital_Status] = useState("");
  let [gender, setGender] = useState("");
  let [empStartDate, setEmpStartDate] = useState("");
  let [empEndDate, setEmpEndDate] = useState("");
  let [generalAvailability, setGeneralAvailability] = useState([]);
  const [imgLoader, setImgLoader] = useState(false);

  function awsLocationHandler(locVal) {
    setlocation(locVal);
  }

  const editCareteamMember = async (postData) => {
    let editData = {};
    let data = {
      ...postData,
      speciality: postData?.speciality?.value,
      Role: postData?.Role?.value,
      carerRelationShip: postData?.carerRelationShip?.value,
      citizenship: postData?.citizenship?.value,
    };

    let type = postData === "available" ? data : "edit";
    if (type === "edit" && editable === 1) {
      editable = 2;
      return;
    }
    if (type === "edit") {
      data.marital_Status = marital_Status;
      data.gender = gender;
      if (location) {
        data.location = location?.Label;
        data.latitude = location?.latitude;
        data.longitude = location?.longitude;
      }
      data.mobileNo = mobileNo;
      editData = getChangedKeysVals(editFormData, data);
      if (Object.keys(editData).length < 1) {
        setchangeBtn(true);
        editable = 1;
        return;
      }
    } else {
      editData = {
        generalAvaliablity:
          alldaysFields.length > 0
            ? [
                { name: "monday", value: alldaysFields, edit: true },
                { name: "tuesday", value: alldaysFields, edit: true },
                { name: "wednesday", value: alldaysFields, edit: true },
                { name: "thursday", value: alldaysFields, edit: true },
                { name: "friday", value: alldaysFields, edit: true },
                { name: "saturday", value: alldaysFields, edit: true },
                { name: "sunday", value: alldaysFields, edit: true },
              ]
            : [
                { name: "monday", value: mondaysFields, edit: true },
                { name: "tuesday", value: tuesdaysFields, edit: true },
                { name: "wednesday", value: wednesdaysFields, edit: true },
                { name: "thursday", value: thursdaysFields, edit: true },
                { name: "friday", value: fridaysFields, edit: true },
                { name: "saturday", value: saturdaysFields, edit: true },
                { name: "sunday", value: sundaysFields, edit: true },
              ],
      };
    }
    const res = await httpAdmin(
      `editCareteamMember/${careteamId}`,
      "post",
      editData,
      null
    );
    if (res.status) {
      setFieldValues(res.data);
      editable = 1;
      if (type === "edit") setchangeBtn(true);
    }
    ShowSwal(res.status, res.message);
  };

  useEffect(() => {
    getCareTeambyId();
    getHolidaysData();
  }, []);

  let params = useParams();

  const getCareTeambyId = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/careTeambyId?careteamId=${careteamId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        const CareTeamData = res.data?.careteamData;
        setImgLoader(false);
        setFieldValues(CareTeamData);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  function setFieldValues(CareTeamData) {
    let gnability = [];
    if (CareTeamData?.generalAvaliablity?.length > 0) {
      if (CareTeamData?.generalAvaliablity?.[0]?.edit) {
        gnability = CareTeamData?.generalAvaliablity;
        setmondaysfields(gnability[0].value);
        settuesdaysfields(gnability[1].value);
        setwednesdaysfields(gnability[2].value);
        setthursdaysfields(gnability[3].value);
        setfridaysfields(gnability[4].value);
        setsaturdaysfields(gnability[5].value);
        setsundaysfields(gnability[6].value);
      } else {
        gnability = JSON.parse(CareTeamData.generalAvaliablity);
        setmondaysfields(gnability[0].value);
        settuesdaysfields(gnability[1].value);
        setwednesdaysfields(gnability[2].value);
        setthursdaysfields(gnability[3].value);
        setfridaysfields(gnability[4].value);
        setsaturdaysfields(gnability[5].value);
        setsundaysfields(gnability[6].value);
      }
    }

    setGeneralAvailability(gnability);
    setmobileNo(CareTeamData.mobileNo);
    setmarital_Status(CareTeamData.marital_Status);
    setGender(CareTeamData.gender);
    setEmpStartDate(CareTeamData.startDate);
    setEmpEndDate(CareTeamData.endDate);
    setEditFormData(CareTeamData);
  }
  // --------- Edit Normal Work Hours of Weeks from Care Team Members ---------- //
  // const [generalAvaliablity, setGeneralAvaliablity] = useState([])

  // -------------- allday shifts timing ----------------- //
  const [alldaysfield, setalldaysfiled] = useState("00:00");
  const [alldaysendfield, setalldaysendfiled] = useState("00:30");
  const [alldaysFields, setalldaysfields] = useState([]);

  const handlealldaysChange = (starttime, endtime) => {
    setalldaysfields((prev) => [...prev, { starttime, endtime }]);
    setalldaysfiled("00:00");
    setalldaysendfiled("00:30");
  };

  const alldaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = alldaysFields.slice();
    clonefield.splice(removeIdx, 1);
    setalldaysfields(clonefield);
  };

  // -------------- Monday shifts timing ----------------- //
  const [mondaysfield, setmondaysfiled] = useState("00:00");
  const [mondaysendfield, setmondaysendfiled] = useState("00:30");
  const [mondaysFields, setmondaysfields] = useState([]);

  const handlemondaysChange = (start, end) => {
    setmondaysfields((prev) => [...prev, { starttime: start, endtime: end }]);
    setmondaysfiled("00:00");
    setmondaysendfiled("00:30");
  };

  const mondaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = mondaysFields.slice();
    clonefield.splice(removeIdx, 1);
    setmondaysfields(clonefield);
  };

  // -------------- Tuesday shifts timing ----------------- //
  const [tuesdaysfield, settuesdaysfiled] = useState("00:00");
  const [tuesdaysendfield, settuesdaysendfiled] = useState("00:30");
  const [tuesdaysFields, settuesdaysfields] = useState([]);

  const handletuesdaysChange = (starttime, endtime) => {
    settuesdaysfields((prev) => [...prev, { starttime, endtime }]);
    settuesdaysfiled("00:00");
    settuesdaysendfiled("00:30");
  };

  const tuesdaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = tuesdaysFields.slice();
    clonefield.splice(removeIdx, 1);
    settuesdaysfields(clonefield);
  };

  // -------------- Wednesday shifts timing ----------------- //
  const [wednesdaysfield, setwednesdaysfiled] = useState("00:00");
  const [wednesdaysendfield, setwednesdaysendfiled] = useState("00:30");
  const [wednesdaysFields, setwednesdaysfields] = useState([]);

  const handlewednesdaysChange = (starttime, endtime) => {
    setwednesdaysfields((prev) => [...prev, { starttime, endtime }]);
    setwednesdaysfiled("00:00");
    setwednesdaysendfiled("00:30");
  };

  const wednesdaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = wednesdaysFields.slice();
    clonefield.splice(removeIdx, 1);
    setwednesdaysfields(clonefield);
  };

  // -------------- Thursday shifts timing ----------------- //
  const [thursdaysfield, setthursdaysfiled] = useState("00:00");
  const [thursdaysendfield, setthursdaysendfiled] = useState("00:30");
  const [thursdaysFields, setthursdaysfields] = useState([]);

  const handlethursdaysChange = (starttime, endtime) => {
    setthursdaysfields((prev) => [...prev, { starttime, endtime }]);
    setthursdaysfiled("00:00");
    setthursdaysendfiled("00:30");
  };

  const thursdaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = thursdaysFields.slice();
    clonefield.splice(removeIdx, 1);
    setthursdaysfields(clonefield);
  };

  // -------------- Friday shifts timing ----------------- //
  const [fridaysfield, setfridaysfiled] = useState("00:00");
  const [fridaysendfield, setfridaysendfiled] = useState("00:30");
  const [fridaysFields, setfridaysfields] = useState([]);

  const handlefridaysChange = (starttime, endtime) => {
    setfridaysfields((prev) => [...prev, { starttime, endtime }]);
    setfridaysfiled("00:00");
    setfridaysendfiled("00:30");
  };

  const fridaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = fridaysFields.slice();
    clonefield.splice(removeIdx, 1);
    setfridaysfields(clonefield);
  };

  // -------------- Saturday shifts timing ----------------- //
  const [saturdaysfield, setsaturdaysfiled] = useState("00:00");
  const [saturdaysendfield, setsaturdaysendfiled] = useState("00:30");
  const [saturdaysFields, setsaturdaysfields] = useState([]);

  const handlesaturdaysChange = (starttime, endtime) => {
    setsaturdaysfields((prev) => [...prev, { starttime, endtime }]);
    setsaturdaysfiled("00:00");
    setsaturdaysendfiled("00:30");
  };

  const saturdaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = saturdaysFields.slice();
    clonefield.splice(removeIdx, 1);
    setsaturdaysfields(clonefield);
  };

  // -------------- Sunday shifts timing ----------------- //
  const [sundaysfield, setsundaysfiled] = useState("00:00");
  const [sundaysendfield, setsundaysendfiled] = useState("00:30");
  const [sundaysFields, setsundaysfields] = useState([]);

  const weekNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handlesundaysChange = (starttime, endtime) => {
    setsundaysfields((prev) => [...prev, { starttime, endtime }]);
    setsundaysfiled("00:00");
    setsundaysendfiled("00:30");
  };

  const sundaysRemoveChangeHandler = (removeIdx) => {
    const clonefield = sundaysFields.slice();
    clonefield.splice(removeIdx, 1);
    setsundaysfields(clonefield);
  };

  // ========================   Holiday section starts   =================================  //
  const careSiteId = currentCareSiteId;
  const [isOpen, setIsOpen] = useState(false);
  const [ids, setIDs] = useState([]);
  const [holidaysData, setHolidaysData] = useState([]);
  const [holidayDetails, setHolidayDetails] = useState({});
  const [isEditOpen, setIsEditOpen] = useState(false);

  function holidayEvent(id) {
    const holidayDetail = holidaysData.find((mid) => mid._id == id);
    setHolidayDetails(holidayDetail);
  }

  // Multiple row selection
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    setIDs(rowSelectionModel);
  }, [rowSelectionModel]);

  const getHolidaysData = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getLeaves?careSiteId=${careSiteId}&careTeamId=${careteamId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let holidaydata = res.data.data;
        let holidayArr = [];
        holidaydata?.forEach((elem) => {
          let approveby = `${elem.approvedBy?.firstName} ${elem.approvedBy?.lastName}`;
          holidayArr.push({
            id: elem._id,
            year: elem?.Date?.from?.slice(0, 4),
            fromDate: elem?.Date?.from,
            toDate: elem?.Date?.to,
            approvedBy: approveby ? approveby : "",
          });
        });
        setHolidaysData(holidayArr);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  function resetForm() {
    reset();
    resetS();
    setIsOpen(false);
    setIsEditOpen(false);
  }
  // =========================  Add and edit Holidays for care team members  ===================== //
  const userData = JSON.parse(localStorage.getItem("userData"));

  function addLeave({ from, to }) {
    let dates = {
      from: from,
      to: to,
    };
    let data = {
      careTeamId: careteamId,
      careSiteId: careSiteId,
      adminId: userData._id,
      dates: dates,
    };
    if (holidayDetails._id) {
      data.leaveId = holidayDetails._id;
    }
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addLeave`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    axios(config)
      .then(function (response) {
        swal(
          response.data.status ? "Success" : "Failed",
          response.data.message,
          response.data.status ? "success" : "error"
        ).then((value) => {
          resetForm();
          getHolidaysData();
        });
      })
      .catch(function (error) {
        if (error.response) {
          swal("Failed", error.response.data.message, "error");
        } else if (error.request) {
          console.log(error.request);
        } else {
          swal("Failed", error.message, "error");
        }
        console.log(error.config);
      });
  }

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // ---------------------     delete multiple  api    ----------------------------------  //

  async function deletemultiple() {
    try {
      const result = await fetch4(
        `${process.env.REACT_APP_BASEURL}/deleteLeave`,
        { careTeamId: careteamId, careSiteId: careSiteId, leaveId: ids },
        { Authorization: localStorage.getItem("care_admin_token") }
      );

      swal(
        result.status ? "Success" : "Failed",
        result.message,
        result.status ? "success" : "error"
      ).then((value) => {
        handleCloseDeletePopup();
        getHolidaysData();
        return;
      });
    } catch (e) {
      swal("Failed", "Oops, Something went wrong", "error");
    }
  }

  // -----------  Approved Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isApprovedOpen, setIsApprovedOpen] = useState(false);
  const handleCloseApprovedPopup = () => setIsApprovedOpen(false);
  const handleShowApprovedPopup = () => setIsApprovedOpen(true);

  // ---------------------     Approved multiple  api    ----------------------------------  //

  async function approvedmultiple() {
    try {
      const result = await fetch4(
        `${process.env.REACT_APP_BASEURL}/approveLeave`,
        {
          careTeamId: careteamId,
          careSiteId: careSiteId,
          leaveId: ids[0],
          leaveStatus: true,
        },
        { Authorization: localStorage.getItem("care_admin_token") }
      );
      swal(
        result.status ? "Approved" : "Failed",
        result.status ? "Leave Approved Successfully" : result.message,
        result.status ? "success" : "error"
      ).then((value) => {
        handleCloseApprovedPopup();
        getHolidaysData();
        return;
      });
    } catch (e) {
      swal("Failed", "Oops, Something went wrong", "error");
    }
  }

  async function onFileChange(e) {
    let profileImg = e.target.files;
    if (profileImg) {
      setImgLoader(true);
      let compressedImg = await compressImage(profileImg);
      editImage(compressedImg);
    }
  }

  const editImage = async (imgg) => {
    let body = new FormData();
    body.append("image", imgg);
    const res = await httpAdmin(
      `editCareteamMember/${careteamId}`,
      "post",
      body,
      "formdata"
    );
    if (res.status) {
      setFieldValues(res.data);
      setImgLoader(false);
    }
  };

  const [selectedDay, setSelectedDay] = useState("All Days");

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  function phoneInput(value, data) {
    setmobileNo(value);
    // setDialCode(`+${data.dialCode}`);
    // setcuntrycode(`${data.countryCode}`);
  }

  const handleSelect = (value) => {
    if (!value) {
      return null;
    }
    return { label: value, value };
  };
  function handleEdit() {
    setValue("speciality", handleSelect(editFormData?.speciality));
    setValue("Role", handleSelect(editFormData?.Role));
    setValue(
      "carerRelationShip",
      handleSelect(editFormData?.carerRelationShip)
    );
  }

  return (
    <>
      <div style={{ height: "78vh", overflowY: "auto", overflowX: "hidden" }}>
        <div id="careteamProfilePage" className="row">
          <div className="col-md-6 pe-3">
            <div className="card_inbox">
              <div className="view_details position-relative">
                <form onSubmit={handleEditSubmit(editCareteamMember)}>
                  {imgLoader ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <img
                      alt="user"
                      className={`profile_img ${
                        editFormData?.image?.includes("chatUser")
                          ? "forImagecareteam"
                          : ""
                      }`}
                      src={
                        editFormData?.image ? editFormData?.image : chatUserImg
                      }
                    />
                  )}
                  <div className="edit_imgicon">
                    <input
                      type="file"
                      id="upLoader"
                      accept="image/*"
                      name="images"
                      onChange={onFileChange}
                    />
                    <span>
                      <AiFillPlusCircle className="edit_icon" />
                    </span>
                  </div>
                  <div className="float-end btns_head mb-3">
                    {changeBtn ? (
                      <button
                        className="btn btn-theme btn-sm"
                        type="button"
                        onClick={() => {
                          handleEdit();
                          setchangeBtn(false);
                        }}
                        disabled={Profileaccess !== "full"}
                      >
                        Edit
                      </button>
                    ) : (
                      <button className="btn btn-theme btn-sm" type="submit">
                        Save
                      </button>
                    )}
                  </div>
                  <table className="table table-striped table_half">
                    <tbody>
                      <tr>
                        <td>First Name</td>
                        {changeBtn ? (
                          <td>{editFormData.first_Name}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              defaultValue={editFormData.first_Name}
                              name="first_Name"
                              className="form-control form-control-sm"
                              {...editForm("first_Name")}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Last Name</td>
                        {changeBtn ? (
                          <td>{editFormData.last_Name}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              defaultValue={editFormData.last_Name}
                              className="form-control form-control-sm"
                              {...editForm("last_Name")}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Gender</td>
                        {changeBtn ? (
                          <td>{editFormData?.gender}</td>
                        ) : (
                          <td>
                            <Select
                              placeholder="Select"
                              defaultValue={{
                                value: editFormData?.gender,
                                label: editFormData?.gender,
                              }}
                              options={[
                                { value: "Male", label: "Male" },
                                { value: "Female", label: "Female" },
                                { value: "Other", label: "Other" },
                              ]}
                              onChange={(event) => {
                                setGender(event.value);
                              }}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Job Title</td>
                        {changeBtn ? (
                          <td>{editFormData?.jobTitle}</td>
                        ) : (
                          <td>
                            <select
                              className="form-select form-select-sm"
                              defaultValue={editFormData.jobTitle}
                              {...editForm("jobTitle")}
                            >
                              <option value={""}>-Select-</option>
                              {jobTitleOptions.map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Role</td>
                        {changeBtn ? (
                          <td>{editFormData?.Role || "--"}</td>
                        ) : (
                          <td>
                            <Controller
                              control={formControl}
                              name="Role"
                              // rules={{
                              //   required: "This field is required",
                              // }}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                    }}
                                    value={field.value} // Sets the value from the form state
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={roles}
                                    // onChange={handleStatusChange}
                                  />
                                );
                              }}
                            />
                            <p className="error_style">
                              {errors.legelStatusOnAdmission?.message ||
                                errors.legelStatusOnAdmission?.label.message}
                            </p>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Speciality</td>
                        {changeBtn ? (
                          <td>{editFormData?.speciality || "--"}</td>
                        ) : (
                          <td>
                            <Controller
                              control={formControl}
                              name="speciality"
                              // rules={{
                              //   required: "This field is required",
                              // }}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                    }}
                                    value={field.value} // Sets the value from the form state
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={speciality}
                                    // onChange={handleStatusChange}
                                  />
                                );
                              }}
                            />
                            <p className="error_style">
                              {errors.legelStatusOnAdmission?.message ||
                                errors.legelStatusOnAdmission?.label.message}
                            </p>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Qualification</td>
                        {changeBtn ? (
                          <td>{editFormData.qualification}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              defaultValue={editFormData.qualification}
                              className="form-control form-control-sm"
                              {...editForm("qualification")}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Date of Birth</td>
                        {changeBtn ? (
                          <td>{editFormData.DOB}</td>
                        ) : (
                          <td>
                            <input
                              type="date"
                              defaultValue={editFormData.DOB}
                              className="form-control form-control-sm"
                              {...editForm("DOB")}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Location</td>
                        {changeBtn ? (
                          <td>{editFormData.location}</td>
                        ) : (
                          <td>
                            <AwsLocation
                              awsLocationHandler={awsLocationHandler}
                              defaultVal={editFormData?.location}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Contact Number</td>
                        {changeBtn ? (
                          <td>{editFormData.mobileNo}</td>
                        ) : (
                          <td>
                            <PhoneInput
                              defaultMask={mobileNo}
                              country={"us"}
                              value={mobileNo}
                              onChange={phoneInput}
                              isValid={(value, country) => {
                                if (
                                  value.match(
                                    /\+?\d{1,9}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
                                  )
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Email </td>
                        {changeBtn ? (
                          <td>{editFormData.email}</td>
                        ) : (
                          <td>
                            <input
                              type="email"
                              defaultValue={editFormData.email}
                              className="form-control form-control-sm"
                              {...editForm("email", {
                                pattern:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              })}
                            />
                            {editFormerrors?.email?.type === "pattern" && (
                              <p className="error_style">
                                Invalid email address
                              </p>
                            )}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Marital Status</td>
                        {changeBtn ? (
                          <td>{editFormData.marital_Status}</td>
                        ) : (
                          <td>
                            <Select
                              placeholder="Select"
                              defaultValue={{
                                value: editFormData.marital_Status,
                                label: editFormData.marital_Status,
                              }}
                              options={maritalStatusOptions}
                              onChange={(event) => {
                                setmarital_Status(event.value);
                              }}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Religion</td>
                        {changeBtn ? (
                          <td>{editFormData?.religion}</td>
                        ) : (
                          <td>
                            <Controller
                              control={control}
                              name="religion"
                              // rules={{
                              //   required: "This field is required",
                              // }}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                    }}
                                    value={field.value} // Sets the value from the form state
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={religions}
                                    // onChange={handleStatusChange}
                                  />
                                );
                              }}
                            />
                          </td>
                        )}
                      </tr>
                      {/* <tr>
                      <td>religion</td>
                      {changeBtn ? (
                        <td>{editFormData.religion}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            defaultValue={editFormData.religion}
                            className="form-control form-control-sm"
                            {...editForm("religion")}
                          />
                        </td>
                      )}
                    </tr> */}
                      <tr>
                        <td>Citizenship</td>
                        {changeBtn ? (
                          <td>{editFormData.citizenship}</td>
                        ) : (
                          <td>
                            <Controller
                              control={control}
                              name="citizenship"
                              // rules={{
                              //   required: "This field is required",
                              // }}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                    }}
                                    value={field.value} // Sets the value from the form state
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={getLabelValArray(
                                      countryList,
                                      "name"
                                    )}
                                    // onChange={handleStatusChange}
                                  />
                                );
                              }}
                            />
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td>Languages</td>
                        {changeBtn ? (
                          <td>{editFormData?.firstLanguage}</td>
                        ) : (
                          <td>
                            <Controller
                              control={control}
                              name="language"
                              // rules={{
                              //   required: "This field is required",
                              // }}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                    }}
                                    value={field.value} // Sets the value from the form state
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={firstLanguage}
                                    // onChange={handleStatusChange}
                                  />
                                );
                              }}
                            />
                            <p className="error_style">
                              {errors.legelStatusOnAdmission?.message ||
                                errors.legelStatusOnAdmission?.label.message}
                            </p>
                          </td>
                        )}
                      </tr>
                      {/* <tr>
                      <td>Languages</td>
                      {changeBtn ? (
                        <td>{editFormData.language}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            defaultValue={editFormData.language}
                            className="form-control form-control-sm"
                            {...editForm("language")}
                          />
                        </td>
                      )}
                    </tr> */}

                      <tr>
                        <td>Employment Start Date</td>
                        {changeBtn ? (
                          <td>{editFormData.startDate}</td>
                        ) : (
                          <td>
                            <input
                              type="date"
                              defaultValue={editFormData.startDate}
                              // onChange={(e) => setEmpStartDate(e.target.value)}
                              className="form-control form-control-sm"
                              {...editForm("startDate")}
                            />
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td>Employment End Date</td>
                        {changeBtn ? (
                          <td>{editFormData.endDate}</td>
                        ) : (
                          <td>
                            <input
                              type="date"
                              defaultValue={editFormData.endDate}
                              // onChange={(e) => setEmpEndDate(e.target.value)}
                              className="form-control form-control-sm"
                              {...editForm("endDate")}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Subject of care for Care-Worker</td>
                        {changeBtn ? (
                          <td>{editFormData.subjectOfCareForCareWorker}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              defaultValue={
                                editFormData.subjectOfCareForCareWorker
                              }
                              placeholder="Subject of care for Care-Worker"
                              className="form-control form-control-sm"
                              {...editForm("subjectOfCareForCareWorker")}
                            />
                          </td>
                        )}
                      </tr>
                      {/* <tr>
                      <td>Subject of Care for Care-Provider</td>
                      {changeBtn ? (
                        <td>{editFormData.subjectOfCareForProvidecare}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            defaultValue={
                              editFormData.subjectOfCareForProvidecare
                            }
                            placeholder="Subject of care for Care-Provider"
                            className="form-control form-control-sm"
                            {...editForm("subjectOfCareForProvidecare")}
                          />
                        </td>
                      )}
                    </tr> */}
                      <tr>
                        <td>Key worker</td>
                        {changeBtn ? (
                          <td>{editFormData.keyWorker}</td>
                        ) : (
                          <td>
                            <select
                              defaultValue={editFormData.keyWorker}
                              className="form-control form-control-sm"
                              {...editForm("keyWorker")}
                            >
                              <option value="">--Select--</option>
                              {["Yes", "No"].map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Household member</td>
                        {changeBtn ? (
                          <td>{editFormData.householdMember}</td>
                        ) : (
                          <td>
                            <select
                              defaultValue={editFormData.householdMember}
                              className="form-control form-control-sm"
                              {...editForm("householdMember")}
                            >
                              <option value="">--Select--</option>
                              {["Yes", "No"].map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </td>
                        )}
                      </tr>

                      {/* <tr>
                      <td>Ordinarily resident in</td>
                      {changeBtn ? (
                        <td>{editFormData.ordinarilyResidentIn}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            defaultValue={editFormData.ordinarilyResidentIn}
                            placeholder="Ordinarily resident in"
                            className="form-control form-control-sm"
                            {...editForm("ordinarilyResidentIn")}
                          />
                        </td>
                      )}
                    </tr> */}

                      <tr>
                        <td>Carer relationship</td>
                        {changeBtn ? (
                          <td>{editFormData?.carerRelationShip || "--"}</td>
                        ) : (
                          <td>
                            <Controller
                              control={formControl}
                              name="carerRelationShip"
                              // rules={{
                              //   required: "This field is required",
                              // }}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                    }}
                                    value={field.value} // Sets the value from the form state
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={careRelationship}
                                    // onChange={handleStatusChange}
                                  />
                                );
                              }}
                            />
                            <p className="error_style">
                              {errors.legelStatusOnAdmission?.message ||
                                errors.legelStatusOnAdmission?.label.message}
                            </p>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Relationship description</td>
                        {changeBtn ? (
                          <td>{editFormData.relatonshipDescription}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              defaultValue={editFormData.relatonshipDescription}
                              placeholder="Relationship description"
                              className="form-control form-control-sm"
                              {...editForm("relatonshipDescription")}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Care organisation</td>
                        {changeBtn ? (
                          <td>{editFormData.careOrganisation}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              defaultValue={editFormData.careOrganisation}
                              placeholder="Care organisation"
                              className="form-control form-control-sm"
                              {...editForm("careOrganisation")}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>Care team</td>
                        {changeBtn ? (
                          <td>{editFormData.careTeam}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              defaultValue={editFormData.careTeam}
                              placeholder="Care team"
                              className="form-control form-control-sm"
                              {...editForm("careTeam")}
                            />
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </form>
                <div className="mb-4 btns_head">
                  <h5 className="tb_general_title mt-0">Normal Work Hours </h5>
                  <table className="table table-striped table_half">
                    <tbody>
                      <tr>
                        <td>Weekly Normal Hours Threshold</td>
                        {changeBtn ? (
                          <td>{editFormData?.weeklyPlannedHours}</td>
                        ) : (
                          <td>
                            <input
                              type="number"
                              defaultValue={editFormData?.weeklyPlannedHours}
                              // onChange={(e) =>
                              //   setWeeklyPlannedHours(e.target.value)
                              // }
                              className="form-control form-control-sm"
                              {...editForm("weeklyPlannedHours")}
                            />
                          </td>
                        )}
                        {/* <select className="form-select form-select-sm">
                                                   <option>English</option>
                                                   <option>Hindi</option>
                                                </select> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 ps-3">
            {/* changes by jitender "card_inbox" this class and div new added - 12/1/2022 */}
            <div className="card_inbox">
              {/* sShowing Medical Condition */}
              <div className="mb-4 btns_head">
                <h5 className="tb_title mt-0 d-flex justify-content-between">
                  General Availability{" "}
                  <button
                    className="btn btn-theme btn-sm"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_team_member"
                    disabled={Profileaccess !== "full"}
                  >
                    Edit
                  </button>
                </h5>

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Weekday</th>
                      <th>Shift timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {generalAvailability?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{weekNames[index]}</td>{" "}
                          {/* Use weekNames array to display the weekday */}
                          <td>
                            {" "}
                            {item.value.map((time, i) => {
                              return (
                                <div key={i * 20}>
                                  {time.starttime} - {time.endtime}
                                </div>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Weekday</th>
                    <th>Shift timing</th>
                  </tr>
                </thead>
                <tbody>
                  {generalAvailability.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                          {" "}
                          {item.value.map((time, i) => {
                            return (
                              <div key={i * 20}>
                                {time.starttime} - {time.endtime}
                              </div>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table> */}
              </div>
            </div>

            <div className="card_inbox mt-4">
              {/* sShowing Medical Condition */}
              <div className="mb-4 btns_head">
                <h5 className="tb_title mt-0 d-flex justify-content-between">
                  Holidays{" "}
                  <DropdownButton id="dropdown-basic-button" title="Actions">
                    <Dropdown.Item
                      // className="btn btn-theme btn-sm float-end"
                      onClick={() => {
                        setIsOpen(true);
                        setHolidayDetails({});
                      }}
                      disabled={Profileaccess !== "full"}
                    >
                      Add
                    </Dropdown.Item>
                    <Dropdown.Item
                      data-bs-toggle="modal"
                      data-bs-target="#edit_medi_cond"
                      disabled={Profileaccess !== "full"}
                      onClick={() => {
                        if (ids.length === 1) {
                          setIsEditOpen(true);
                          holidayEvent(ids[0]);
                        } else
                          swal(
                            "Failed",
                            "Please Select at least one client",
                            "error"
                          ).then((value) => {
                            return;
                          });
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={Profileaccess !== "full"}
                      onClick={() => {
                        if (ids.length >= 1) {
                          setHolidayDetails({});
                          handleShowDeletePopup(true);
                        } else
                          swal(
                            "Failed",
                            "Please Select at least one client",
                            "error"
                          ).then((value) => {
                            return;
                          });
                      }}
                    >
                      Delete{" "}
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                    onClick={() => {
                      if (ids.length === 1) {
                        setHolidayDetails({});
                        handleShowApprovedPopup(true);
                      } else
                        swal("Failed", "Please Select only one", "error").then(
                          (value) => {
                            return;
                          }
                        );
                    }}
                  >
                    Approved{" "}
                  </Dropdown.Item> */}
                  </DropdownButton>
                </h5>
              </div>

              <Box sx={{ height: 300, width: "100%" }}>
                <DataGrid
                  rows={holidaysData}
                  columns={columns}
                  checkboxSelection
                  getRowId={(row) => row.id}
                  disableRowSelectionOnClick
                  rowSelectionModel={rowSelectionModel}
                  slots={{
                    noRowsOverlay: NoRowsOverlay,
                  }}
                  onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                />
              </Box>

              {/* add leave */}
              <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                <Modal.Body>
                  <div
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <form onSubmit={handleSubmit(addLeave)}>
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Holiday
                            </h5>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <label className="form-label">From Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  {...register("from", {
                                    required: true,
                                  })}
                                  name="from"
                                />

                                {errors?.from?.type === "required" && (
                                  <p className="error_style">
                                    This field is required
                                  </p>
                                )}
                              </div>

                              <div className="col-md-12 mb-3">
                                <label className="form-label">To Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  {...register("to", {
                                    required: true,
                                  })}
                                  name="to"
                                />

                                {errors?.to?.type === "required" && (
                                  <p className="error_style">
                                    This field is required
                                  </p>
                                )}
                              </div>
                              {/* <div className="col-md-12 mb-3">
                              <label className="form-label">Created By</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("createdBy", {
                                  required: true,
                                })}
                                name="createdBy"
                              />
                              {errors?.createdBy?.type === "required" && (
                                <p className="error_style">This field is required</p>
                              )}
                            </div> */}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                reset();
                                resetForm();
                                setIsOpen(false);
                              }}
                            >
                              Close
                            </button>
                            <button type="submit" className="btn btn-success">
                              Create
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* edit leave */}
              <Modal
                show={isEditOpen}
                onHide={() => {
                  setIsEditOpen(false);
                }}
              >
                <Modal.Header>
                  <Modal.Title>Edit Holiday Dates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleSubmitS(addLeave)}>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label">From Date</label>
                        <input
                          type="date"
                          className="form-control"
                          defaultValue={holidayDetails?.Date?.from}
                          {...registerS("from", {
                            required: true,
                          })}
                        />
                        {errorsS?.from?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">To Date</label>
                        <input
                          type="date"
                          className="form-control"
                          defaultValue={holidayDetails?.Date?.to}
                          {...registerS("to", {
                            required: true,
                          })}
                        />
                        {errorsS?.to?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            reset();
                            setIsEditOpen(false);
                          }}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-success">
                          Save
                        </button>
                      </div>
                    </Modal.Footer>
                  </form>
                </Modal.Body>
              </Modal>

              {/* delete leave */}
              <Modal
                className="viewModal"
                show={isDeleteOpen}
                onHide={handleCloseDeletePopup}
              >
                <Modal.Header closeButton2>
                  <Modal.Title>
                    <span>Delete Holiday{ids.length > 1 ? "s" : ""} </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <p>
                          Are you sure you want to delete this{" "}
                          {ids.length > 1 ? "Holidays" : "Holiday"} ?
                        </p>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex">
                    <button
                      className="btn submitBtn"
                      onClick={handleCloseDeletePopup}
                    >
                      No
                    </button>
                    <button
                      className="btn submitBtn"
                      onClick={() => {
                        deletemultiple();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>

              {/* Approved leave */}
              <Modal
                className="viewModal"
                show={isApprovedOpen}
                onHide={handleCloseApprovedPopup}
              >
                <Modal.Header closeButton2>
                  <Modal.Title>
                    <span>Approved Holiday{ids.length > 1 ? "s" : ""} </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <p>
                          Are you sure you want to Approved this{" "}
                          {ids.length > 1 ? "Holidays" : "Holiday"} ?
                        </p>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex">
                    <button
                      className="btn submitBtn"
                      onClick={handleCloseApprovedPopup}
                    >
                      No
                    </button>
                    <button
                      className="btn submitBtn"
                      onClick={() => {
                        approvedmultiple();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="edit_team_member"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            col={8}
            className="modal-dialog modal-dialog-scrollable col-md-8"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {" "}
                  Edit Normal Work Hours
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  // onClick={() => {
                  //   getCareTeambyId();
                  // }}
                ></button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className={`days_btn ${
                        selectedDay === "All Days" ? "selected_day" : ""
                      }`}
                      onClick={() => handleDayClick("All Days")}
                    >
                      All Days
                    </button>
                    {weekDays.map((item) => {
                      return (
                        <button
                          key={item.value}
                          className={`days_btn ${
                            selectedDay === item.label ? "selected_day" : ""
                          }`}
                          onClick={() => handleDayClick(item.label)}
                        >
                          {item.label}
                        </button>
                      );
                    })}
                  </div>
                  <div className="col-md-12">
                    {/* All Days */}
                    {selectedDay === "All Days" && (
                      <>
                        <div className="d-flex my-3 justify-content">
                          <select
                            className="form-select custom_timeInput"
                            value={alldaysfield}
                            onChange={(e) => setalldaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={alldaysendfield}
                            onChange={(e) => setalldaysendfiled(e.target.value)}
                          >
                            {timeValues
                              .filter((item) => item.value > alldaysfield)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handlealldaysChange(
                                alldaysfield,
                                alldaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>

                        {alldaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex general"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  alldaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {/* Monday */}
                    {selectedDay === "Monday" && (
                      <>
                        <div className="d-flex my-3 justify-content">
                          <select
                            className="form-select custom_timeInput"
                            value={mondaysfield}
                            onChange={(e) => setmondaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={mondaysendfield}
                            onChange={(e) => setmondaysendfiled(e.target.value)}
                          >
                            {timeValues
                              .filter((item) => item.value > mondaysfield)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handlemondaysChange(
                                mondaysfield,
                                mondaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>

                        {mondaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex general"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  mondaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {/* Tuesday */}

                    {selectedDay === "Tuesday" && (
                      <>
                        <div className="d-flex my-3">
                          <select
                            className="form-select custom_timeInput"
                            value={tuesdaysfield}
                            onChange={(e) => settuesdaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={tuesdaysendfield}
                            onChange={(e) =>
                              settuesdaysendfiled(e.target.value)
                            }
                          >
                            {timeValues
                              .filter((item) => item.value > tuesdaysfield)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handletuesdaysChange(
                                tuesdaysfield,
                                tuesdaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>
                        {tuesdaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  tuesdaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {/* Wednesday */}
                    {selectedDay === "Wednesday" && (
                      <>
                        <div className="d-flex my-3">
                          <select
                            className="form-select custom_timeInput"
                            value={wednesdaysfield}
                            onChange={(e) => setwednesdaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={wednesdaysendfield}
                            onChange={(e) =>
                              setwednesdaysendfiled(e.target.value)
                            }
                          >
                            {timeValues
                              .filter((item) => item.value > wednesdaysfield)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handlewednesdaysChange(
                                wednesdaysfield,
                                wednesdaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>
                        {wednesdaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  wednesdaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {/* Thursday */}
                    {selectedDay === "Thursday" && (
                      <>
                        <div className="d-flex my-3">
                          <select
                            className="form-select custom_timeInput"
                            value={thursdaysfield}
                            onChange={(e) => setthursdaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={thursdaysendfield}
                            onChange={(e) =>
                              setthursdaysendfiled(e.target.value)
                            }
                          >
                            {timeValues
                              .filter((item) => item.value > thursdaysfield)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handlethursdaysChange(
                                thursdaysfield,
                                thursdaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>
                        {thursdaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  thursdaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {/* Friday */}
                    {selectedDay === "Friday" && (
                      <>
                        <div className="d-flex my-3">
                          <select
                            className="form-select custom_timeInput"
                            value={fridaysfield}
                            onChange={(e) => setfridaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={fridaysendfield}
                            onChange={(e) => setfridaysendfiled(e.target.value)}
                          >
                            {timeValues
                              .filter((item) => item.value > fridaysFields)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handlefridaysChange(
                                fridaysfield,
                                fridaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>
                        {fridaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  fridaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {/* Saturday */}
                    {selectedDay === "Saturday" && (
                      <>
                        <div className="d-flex my-3">
                          <select
                            className="form-select custom_timeInput"
                            value={saturdaysfield}
                            onChange={(e) => setsaturdaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={saturdaysendfield}
                            onChange={(e) =>
                              setsaturdaysendfiled(e.target.value)
                            }
                          >
                            {timeValues
                              .filter((item) => item.value > saturdaysfield)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handlesaturdaysChange(
                                saturdaysfield,
                                saturdaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>
                        {saturdaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  saturdaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {/* Sunday */}
                    {selectedDay === "Sunday" && (
                      <>
                        <div className="d-flex my-3">
                          <select
                            className="form-select custom_timeInput"
                            value={sundaysfield}
                            onChange={(e) => setsundaysfiled(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="form-select custom_timeInput ms-3"
                            value={sundaysendfield}
                            onChange={(e) => setsundaysendfiled(e.target.value)}
                          >
                            {timeValues
                              .filter((item) => item.value > sundaysfield)
                              .map((item) => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            className="btn theme_btn"
                            onClick={() => {
                              handlesundaysChange(
                                sundaysfield,
                                sundaysendfield
                              );
                            }}
                          >
                            Add
                          </button>
                        </div>
                        {sundaysFields?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="pe-5 mb-3 position-relative d-flex"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value={item.starttime}
                                disabled
                              />
                              <input
                                className="form-control ms-3"
                                type="text"
                                value={item.endtime}
                                disabled
                              />
                              <button
                                className="removeBtn"
                                style={{ top: "3px" }}
                                onClick={() => {
                                  sundaysRemoveChangeHandler(index);
                                }}
                              >
                                -
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  onClick={() => editCareteamMember("available")}
                  className="btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCareTeam;
