import React, { useState, useEffect } from "react";
import axios from "axios";
import { CareTeamDocsTypes } from "../../constants/constants";
import Select from "react-select";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import swal from "sweetalert";
import NoRowsOverlay from "../../components/NoRowsOverlays";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { fetch4 } from "../../Apis/commonApis";
import { AiOutlineClose } from "react-icons/ai";
import PreviewButton from "../../components/PreviewButton";
import "../CareTeam/careteam.css";
import { useSelector } from "react-redux";
import { ShowSwal } from "../../utils/alertSwal";
import {
  formatDate,
  formatDateToDDMMYYYY,
  getFileOriginalName,
} from "../../utils/CommonFunctions";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const columns = [
  { field: "docName", headerName: "Doc Name", width: 190 },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    editable: false,
  },
  {
    field: "creationDate",
    headerName: "Creation date",
    width: 170,
    editable: false,
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 150,
    editable: false,
  },
  {
    field: "review",
    headerName: "Next Review",
    width: 150,
    editable: false,
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "image",
    headerName: "File",
    width: 50,
    editable: false,
    renderCell: (params) => <PreviewButton filedata={params} />,
  },
  {
    field: "fileName",
    headerName: "",
    width: 280,
    editable: false,
  },
];

const AddEditCareTeamDocs = (props) => {
  const { careteamId, Docsaccess } = props;

  const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
  const isPlanBase = currentPlanName === "Base";

  const [isOpen, setIsOpen] = useState(false);
  let [newData, setnewData] = useState({});

  const [docsTableData, setDocsTableData] = useState([]);
  const [teamdocId, setTeamdocId] = useState("");
  const [ids, setIDs] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  useEffect(() => {
    getCareTeambyId();
  }, []);

  useEffect(() => {
    setIDs(rowSelectionModel);
  }, [rowSelectionModel]);

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const {
    register: registerS,
    handleSubmit: handleSubmitS,
    unregister: unregisterS,
    reset: resetS,
    formState: { errors: errorsS },
    control: controlS,
  } = useForm();

  function docEvent(id) {
    resetS();
    const docsData = docsTableData.find((mid) => mid.id == id);
    setnewData(docsData);
    setTeamdocId(docsData.id);
    setIsEditOpen(true);
  }

  function editCareTeamDoc({ docName, docType, review }) {
    let data = JSON.stringify({
      teamdocId: teamdocId,
      docName,
      type: docType.value,
      review,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editcareTeamdoc/${careteamId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        swal(
          response.data.status ? "Success" : "Failed",
          response.data.message,
          response.data.status ? "success" : "error"
        ).then((value) => {
          handleCloseEditModal();
          setnewData({});
          getCareTeambyId();
        });
      })
      .catch(function (error) {
        if (error.response) {
          swal("Failed", error.response.data.message, "error");
        } else if (error.request) {
          console.log(error.request);
        } else {
          swal("Failed", error.message, "error");
        }
        console.log(error.config);
      });
  }

  function resetForm() {
    setIsOpen(false);
  }

  function careTeamDocs({ docName, docType, review, uploadFile }) {
    let formdata = new FormData();
    let user = JSON.parse(localStorage.getItem("userData"));
    let fullName = `${user.firstName} ${user.lastName} (${user.userType})`;

    formdata.append("docName", docName);
    formdata.append("type", docType.value);
    formdata.append("creationDate", new Date().toJSON());
    formdata.append("createdBy", fullName);
    formdata.append("review", review);
    formdata.append("image", uploadFile[0]);

    if (isPlanBase) {
      let fileType = uploadFile[0]?.type;
      if (
        fileType &&
        (fileType?.includes("audio") || fileType?.includes("video"))
      ) {
        ShowSwal(false, "Audio and Video files are not allowed in Base Plan");
        return;
      }
    }
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/careTeamdocs/${careteamId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "multipart/formdata",
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        swal(
          response.data.status ? "Success" : "Failed",
          response.data.message,
          response.data.status ? "success" : "error"
        ).then((value) => {
          reset();
          resetForm();
          getCareTeambyId();
          return;
        });
      })
      .catch(function (error) {
        if (error.response) {
          swal("Failed", error.response.data.message, "error");
        } else if (error.request) {
          console.log(error.request);
        } else {
          swal("Failed", error.message, "error");
        }
        console.log(error.config);
      });
  }

  const getCareTeambyId = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/careTeambyId?careteamId=${careteamId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let CareTeamDocs = res.data?.careteamData?.careTeamDocs;
        let custumArray = [];
        if (CareTeamDocs?.length > 0) {
          CareTeamDocs.forEach((elem) => {
            custumArray.push({
              id: elem._id,
              createdBy: elem?.createdBy,
              creationDate: elem?.creationDate ?? "",
              docName: elem?.docName,
              image: elem?.image,
              review: elem?.review ?? "",
              type: elem?.type,
              fileName: elem?.image ? getFileOriginalName(elem?.image) : "",
            });
          });
        }

        setDocsTableData(custumArray);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  // ---------------------     delete multiple  api    ----------------------------------  //

  async function deletemultiple() {
    try {
      const result = await fetch4(
        `${process.env.REACT_APP_BASEURL}/deleteCareTeamDocs`,
        { careTeamId: careteamId, docId: ids },
        { Authorization: localStorage.getItem("care_admin_token") }
      );
      // console.log(result, "deletedmultiplecareteamdocs");
      swal(
        result.status ? "Success" : "Failed",
        result.message,
        result.status ? "success" : "error"
      ).then((value) => {
        handleCloseDeletePopup();
        getCareTeambyId();
        // return;
      });
    } catch (e) {
      swal("Failed", "Oops, Something went wrong", "error");
    }
  }

  const handleCloseEditModal = () => {
    resetS();
    setIsEditOpen(false);
  };

  return (
    <>
      {currentPlanName === "Turbo" || currentPlanName === "Advanced" ? (
        <div className="row">
          <div className="col-md-12">
            <div className="btns_head topButtonShow">
              <h5 className="tb_title">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Actions"
                  className="d-flex justify-content-end topbtninaction"
                >
                  <Dropdown.Item
                    disabled={Docsaccess !== "full"}
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Add Docs
                  </Dropdown.Item>
                  <Dropdown.Item
                    // className="action_table_btn"
                    disabled={Docsaccess !== "full"}
                    onClick={() => {
                      if (ids?.length === 1) {
                        docEvent(ids[0]);
                      } else {
                        ShowSwal(false, "Please Select only one doc");
                      }
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={Docsaccess !== "full"}
                    onClick={() => {
                      if (ids?.length >= 1) {
                        handleShowDeletePopup(true);
                      } else {
                        ShowSwal(
                          false,
                          "Please Select at least one compliance doc"
                        );
                      }
                    }}
                  >
                    Delete{" "}
                  </Dropdown.Item>
                </DropdownButton>
              </h5>
            </div>
            <div style={{ height: "80vh", width: "100%", paddingTop: "30px" }}>
              <CustomDataGrid
                columns={columns}
                rows={docsTableData}
                // autoHeight
                checkboxSelection
                onSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                disableSelectionOnClick
                rowSelectionModel={rowSelectionModel}
                slots={{
                  noRowsOverlay: NoRowsOverlay,
                }}
              />
            </div>

            {/* add careteam documents */}
            <Modal show={isOpen} onHide={() => setIsOpen(false)}>
              <Modal.Body>
                <div
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <form onSubmit={handleSubmit(careTeamDocs)}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Docs
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            reset();
                            resetForm();
                            setIsOpen(false);
                          }}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Doc Name</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("docName", {
                                required: true,
                              })}
                              name="docName"
                            />

                            {errors?.docName?.type === "required" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mb-1">
                            <label className="form-label">Type</label>
                            {/* React Select Along with React Hook Form */}
                            <Controller
                              control={control}
                              name="docType"
                              rules={{
                                required: "Type is required",
                              }}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={CareTeamDocsTypes}
                                    // classNamePrefix="addl-class"
                                    // options={ComplianceDocsTypes}
                                    // // value={complianceDocsType.find(
                                    // //   (c) => c.value === value
                                    // // )}
                                    // onChange={handleChange}
                                  />
                                );
                              }}
                            />
                            <p className="error_style">
                              {errors.docType?.message ||
                                errors.docType?.label.message}
                            </p>
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <label className="form-label">Creation Date</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("creationDate", {
                                required: true,
                              })}
                              name="creationDate"
                            />

                            {errors?.creationDate?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                          </div> */}
                          {/* <div className="col-md-12 mb-3">
                            <label className="form-label">Created By</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("createdBy", {
                                required: true,
                              })}
                              name="createdBy"
                            />
                            {errors?.createdBy?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                          </div> */}
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Next Review</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("review", {
                                required: false,
                              })}
                              name="review"
                            />{" "}
                            {errors?.review?.type === "required" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">File</label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("uploadFile", {
                                required: "file is required",
                              })}
                              onChange={(e) => {
                                unregister("uploadFile");
                              }}
                              name="uploadFile"
                            />
                            {errors?.uploadFile?.type === "required" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="submit" className="btn btn-success">
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </Modal>

            {/* edit care team docs */}
            {isEditOpen && (
              <Modal
                className="viewModal"
                show={isEditOpen}
                onHide={handleCloseEditModal}
              >
                <Modal.Header>
                  <Modal.Title>
                    <span>Edit Care Team Docs</span>
                    <span>
                      <AiOutlineClose
                        className="curser_point"
                        onClick={handleCloseEditModal}
                      />
                    </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleSubmitS(editCareTeamDoc)}>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <input
                          type="hidden"
                          defaultValue={newData?.id}
                          // onChange={(e) => setEditcomplianceDocs_id(e.target.value)}
                          className="form-control"
                        />
                        <label className="form-label">Doc Name</label>
                        <input
                          type="text"
                          defaultValue={newData?.docName}
                          className="form-control"
                          {...registerS("docName", {
                            required: true,
                          })}
                        />
                        {errorsS?.docName?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                      <div className="col-md-12 mb-1">
                        <label className="form-label">Type</label>

                        {/* React Select Along with React Hook Form */}
                        <Controller
                          control={controlS}
                          name="docType"
                          defaultValue={{
                            label: newData?.type,
                            value: newData?.type,
                          }}
                          rules={{
                            required: "Type is required",
                          }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={CareTeamDocsTypes}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                        <p className="error_style">
                          {errorsS.docType?.message ||
                            errorsS.docType?.label.message}
                        </p>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                    <label className="form-label">Creation Date</label>
                    <input
                      type="date"
                      className="form-control"
                      defaultValue={newData.creationDate}
                      {...registerS("creationDate", {
                        required: true,
                      })}
                    />
                    {errorsS?.creationDate?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div> */}
                      {/* <div className="col-md-12 mb-3">
                    <label className="form-label">Created By</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={newData.createdBy}
                      {...registerS("createdBy", {
                        required: true,
                      })}
                    />
                    {errorsS?.createdBy?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div> */}
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Next Review</label>
                        <input
                          type="date"
                          className="form-control"
                          defaultValue={newData.review}
                          {...registerS("review", {
                            required: false,
                          })}
                        />
                        {errorsS?.review?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex">
                        <button type="submit" className="btn btn-success">
                          Save
                        </button>
                      </div>
                    </Modal.Footer>
                  </form>
                </Modal.Body>
              </Modal>
            )}

            {/* delete care team Docs */}
            <Modal
              className="viewModal"
              show={isDeleteOpen}
              onHide={handleCloseDeletePopup}
            >
              <Modal.Header>
                <Modal.Title>
                  <span>Delete Care Team Docs</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <p>
                        Are you sure you want to delete this Care Team{" "}
                        {ids?.length > 1 ? "docs" : "doc"} ?
                      </p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <button
                    className="btn submitBtn"
                    onClick={handleCloseDeletePopup}
                  >
                    No
                  </button>
                  <button
                    className="btn submitBtn"
                    onClick={() => {
                      deletemultiple();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      ) : (
        <div>This feature is not available in the {currentPlanName} Plan</div>
      )}
    </>
  );
};

export default AddEditCareTeamDocs;
