import React, { useState, useEffect } from "react";
import axios from "axios";
import { CareTeamTrainingTypes } from "../../constants/constants";
import Select from "react-select";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import swal from "sweetalert";
import NoRowsOverlay from "../../components/NoRowsOverlays";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { fetch4 } from "../../Apis/commonApis";
import PreviewButton from "../../components/PreviewButton";
import "../CareTeam/careteam.css";
import { ShowSwal } from "../../utils/alertSwal";
import { useSelector } from "react-redux";
import {
  formatDateToDDMMYYYY,
  getFileOriginalName,
} from "../../utils/CommonFunctions";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const columns = [
  { field: "trainingName", headerName: "Training Name", width: 190 },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    editable: true,
  },
  {
    field: "trainingDate",
    headerName: "Training Date",
    width: 170,
    editable: true,
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "creationDate",
    headerName: "Creation date",
    width: 170,
    editable: true,
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 150,
    editable: true,
  },
  {
    field: "review",
    headerName: "Next Review",
    width: 150,
    editable: true,
    valueFormatter: (params) => {
      return `${formatDateToDDMMYYYY(params.value)}`;
    },
  },
  {
    field: "image",
    headerName: "File",
    width: 50,
    editable: true,
    renderCell: (params) => <PreviewButton filedata={params} />,
  },
  {
    field: "fileName",
    headerName: "",
    width: 280,
    editable: false,
  },
];

const AddEditCareTraining = (props) => {
  const { careteamId, Trainingsaccess } = props;

  const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
  const isPlanBase = currentPlanName === "Base";

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const {
    register: registerS,
    handleSubmit: handleSubmitS,
    unregister: unregisterS,
    reset: resetS,
    formState: { errors: errorsS },
    control: controlS,
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);

  function trainingEvent(id) {
    const items = editTrainingData.find((mid) => mid._id == id);

    setEditTraining(items);
    setTeamtraningId(items._id);
  }
  const [editTraining, setEditTraining] = useState([]);
  const [edittrainingid, setTeamtraningId] = useState("");
  const [ids, setIDs] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  function editTrainingapi({
    trainingName,
    type,
    creationDate,
    trainingDate,
    createdBy,
    review,
  }) {
    let data = JSON.stringify({
      training_id: edittrainingid,
      trainingName,
      type: type.value,
      creationDate,
      trainingDate,
      createdBy,
      review,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editTraining/${careteamId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        swal(
          response.data.status ? "Success" : "Failed",
          response.data.message,
          response.data.status ? "success" : "error"
        ).then((value) => {
          getCareTeambyId();
          resetS();
          setIsEditOpen(false);
        });
      })
      .catch(function (error) {
        if (error.response) {
          swal("Failed", error.response.data.message, "error");
        } else if (error.request) {
          console.log(error.request);
        } else {
          swal("Failed", error.message, "error");
        }
        console.log(error.config);
      });
  }

  function resetForm() {
    setIsOpen(false);
  }

  const [editTrainingData, setEditTrainingData] = useState([]);

  function createTraining({
    trainingName,
    trainingDate,
    // createdBy,
    type,
    review,
    uploadFile,
  }) {
    let formdata = new FormData();
    let user = JSON.parse(localStorage.getItem("userData"));
    let fullName = `${user.firstName} ${user.lastName} (${user.userType})`;

    formdata.append("trainingName", trainingName);
    formdata.append("type", type.value);
    formdata.append("creationDate", new Date().toJSON());
    formdata.append("trainingDate", trainingDate);
    formdata.append("createdBy", fullName);
    formdata.append("review", review);
    formdata.append("image", uploadFile[0]);

    if (isPlanBase) {
      let fileType = uploadFile[0]?.type;
      if (
        fileType &&
        (fileType?.includes("audio") || fileType?.includes("video"))
      ) {
        ShowSwal(false, "Audio and Video files are not allowed in Base Plan");
        return;
      }
    }
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createTraining/${careteamId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "multipart/formdata",
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        swal(
          response.data.status ? "Success" : "Failed",
          response.data.message,
          response.data.status ? "success" : "error"
        ).then((value) => {
          reset();
          resetForm();
          getCareTeambyId();
        });
      })
      .catch(function (error) {
        if (error.response) {
          swal("Failed", error.response.data.message, "error");
        } else if (error.request) {
          console.log(error.request);
        } else {
          swal("Failed", error.message, "error");
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    getCareTeambyId();
  }, []);

  const getCareTeambyId = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/careTeambyId?careteamId=${careteamId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let Tranining = res.data.careteamData.Tranining;
        let custumArray = [];
        if (Tranining) {
          Tranining.forEach((elem) => {
            custumArray.push({
              _id: elem._id,
              trainingName: elem.trainingName,
              createdBy: elem.createdBy,
              creationDate: elem.creationDate ?? "",
              trainingDate: elem.trainingDate ?? "",
              image: elem.image,
              review: elem.review ?? "",
              type: elem.type,
              fileName: elem?.image ? getFileOriginalName(elem?.image) : "",
            });
          });
        }
        setEditTrainingData(custumArray);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    setIDs(rowSelectionModel);
  }, [rowSelectionModel]);

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // ---------------------     delete multiple  api    ----------------------------------  //

  async function deletemultiple() {
    try {
      const result = await fetch4(
        `${process.env.REACT_APP_BASEURL}/deleteTraining`,
        { careTeamId: careteamId, trainingId: ids },
        { Authorization: localStorage.getItem("care_admin_token") }
      );

      swal(
        result.status ? "Success" : "Failed",
        result.message,
        result.status ? "success" : "error"
      ).then((value) => {
        handleCloseDeletePopup();
        getCareTeambyId();
        return;
      });
    } catch (e) {
      swal("Failed", "Oops, Something went wrong", "error");
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="btns_head topButtonShow">
            <h5 className="tb_title">
              {" "}
              <DropdownButton
                id="dropdown-basic-button"
                title="Actions"
                className="d-flex justify-content-end topbtninaction"
              >
                <Dropdown.Item
                  disabled={Trainingsaccess !== "full"}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Add Training{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={Trainingsaccess !== "full"}
                  // className="action_table_btn"
                  onClick={() => {
                    if (ids?.length === 1) {
                      setIsEditOpen(true);
                      trainingEvent(ids[0]);
                    } else {
                      swal(
                        "Failed",
                        "Please Select only one training",
                        "error"
                      ).then((value) => {
                        return;
                      });
                    }
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={Trainingsaccess !== "full"}
                  onClick={() => {
                    if (ids?.length >= 1) handleShowDeletePopup(true);
                    else
                      swal(
                        "Failed",
                        "Please Select at least one compliance doc",
                        "error"
                      ).then((value) => {
                        return;
                      });
                  }}
                >
                  Delete{" "}
                </Dropdown.Item>
              </DropdownButton>
            </h5>
          </div>

          <div style={{ height: "80vh", width: "100%", paddingTop: "30px" }}>
            <CustomDataGrid
              columns={columns}
              rows={editTrainingData}
              // autoHeight
              checkboxSelection
              onSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              disableSelectionOnClick
              getRowId={(row) => row?._id}
              rowSelectionModel={rowSelectionModel}
              slots={{
                noRowsOverlay: NoRowsOverlay,
              }}
            />
          </div>
          {/* creating care team training */}
          <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <form onSubmit={handleSubmit(createTraining)}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Training
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          reset();
                          resetForm();
                          setIsOpen(false);
                        }}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Training Name</label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("trainingName", {
                              required: true,
                            })}
                            name="trainingName"
                          />
                          {errors?.docName?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-1">
                          <label className="form-label">Type</label>
                          {/* React Select Along with React Hook Form */}
                          <Controller
                            control={control}
                            name="type"
                            rules={{
                              required: "Type is required",
                            }}
                            render={({ field }) => {
                              return (
                                <Select
                                  {...field}
                                  isClearable
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={CareTeamTrainingTypes}
                                  // classNamePrefix="addl-class"
                                  // options={ComplianceDocsTypes}
                                  // // value={complianceDocsType.find(
                                  // //   (c) => c.value === value
                                  // // )}
                                  // onChange={handleChange}
                                />
                              );
                            }}
                          />
                          <p className="error_style">
                            {errors.type?.message || errors.type?.label.message}
                          </p>
                        </div>
                        {/* <div className="col-md-12 mb-3">
                            <label className="form-label">Creation Date</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("creationDate", {
                                required: true,
                              })}
                              name="creationDate"
                            />
                            {errors?.creationDate?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                          </div> */}
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Training Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("trainingDate", {
                              required: true,
                            })}
                            name="trainingDate"
                          />
                          {errors?.trainingDate?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>

                        {/* <div className="col-md-12 mb-3">
                            <label className="form-label">Created By</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("createdBy", {
                                required: true,
                              })}
                              name="createdBy"
                            />
                            {errors?.createdBy?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                          </div> */}

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Next Review</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("review", {
                              required: false,
                            })}
                            name="review"
                          />{" "}
                          {errors?.review?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <input
                            type="file"
                            className="form-control"
                            {...register("uploadFile", {
                              required: "file is required",
                            })}
                            onChange={(e) => {
                              unregister("uploadFile");
                            }}
                            name="uploadFile"
                          />
                          {errors?.uploadFile?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-success">
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>

          {/* edit care team training */}
          <Modal
            show={isEditOpen}
            onHide={() => {
              setIsEditOpen(false);
            }}
          >
            <Modal.Header closeButton2>
              <Modal.Title>Edit Training</Modal.Title>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                  setIsEditOpen(false);
                }}
              ></button>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmitS(editTrainingapi)}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="hidden"
                      defaultValue={editTraining._id}
                      // onChange={(e) => setEditcomplianceDocs_id(e.target.value)}
                      className="form-control"
                    />
                    <label className="form-label">Training Name</label>
                    <input
                      type="text"
                      defaultValue={editTraining.trainingName}
                      className="form-control"
                      {...registerS("trainingName", {
                        required: true,
                      })}
                    />
                    {errorsS?.trainingName?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Type</label>

                    {/* React Select Along with React Hook Form */}
                    <Controller
                      control={controlS}
                      name="type"
                      defaultValue={{
                        label: editTraining.type,
                        value: editTraining.type,
                      }}
                      rules={{
                        required: "Type is required",
                      }}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            isClearable
                            isSearchable={false}
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            options={CareTeamTrainingTypes}
                            // onChange={handleStatusChange}
                          />
                        );
                      }}
                    />
                    <p className="error_style">
                      {errorsS.type?.message || errorsS.type?.label.message}
                    </p>
                  </div>
                  {/* <div className="col-md-12 mb-3">
                    <label className="form-label">Creation Date</label>
                    <input
                      type="date"
                      className="form-control"
                      defaultValue={editTraining.creationDate}
                      {...registerS("creationDate", {
                        required: true,
                      })}
                    />
                    {errorsS?.creationDate?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div> */}
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Training Date</label>
                    <input
                      type="date"
                      className="form-control"
                      defaultValue={editTraining.trainingDate}
                      {...registerS("trainingDate", {
                        required: true,
                      })}
                    />
                    {errorsS?.trainingDate?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div>
                  {/* <div className="col-md-12 mb-3">
                    <label className="form-label">Created By</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={editTraining.createdBy}
                      {...registerS("createdBy", {
                        required: true,
                      })}
                    />
                    {errorsS?.createdBy?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div> */}
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Next Review</label>
                    <input
                      type="date"
                      className="form-control"
                      defaultValue={editTraining.review}
                      {...registerS("review", {
                        required: false,
                      })}
                    />
                    {errorsS?.review?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div>
                </div>
                <Modal.Footer>
                  <div className="d-flex">
                    <button type="submit" className="btn btn-success">
                      Save
                    </button>
                  </div>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>

          {/* delete care team Trainings */}
          <Modal
            className="viewModal"
            show={isDeleteOpen}
            onHide={handleCloseDeletePopup}
          >
            <Modal.Header closeButton2>
              <Modal.Title>
                <span>Delete Care Team Training</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <p>
                      Are you sure you want to delete this{" "}
                      {ids?.length > 1 ? "Trainings" : "Training"} ?
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <button
                  className="btn submitBtn"
                  onClick={handleCloseDeletePopup}
                >
                  No
                </button>
                <button
                  className="btn submitBtn"
                  onClick={() => {
                    deletemultiple();
                  }}
                >
                  Yes
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AddEditCareTraining;
